import { GlobeIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline";
import ImpactDataBoxes from "./ImpactDataBoxes";
import { Disclosure } from "@headlessui/react";
import { state, useSnapshot } from "../../state/stateStore";
import { Link } from "react-router-dom";
import { formatNumber } from "../Helpers/helpers";

const faqs = [
  {
    question: "What is Eden Projects?",
    answer:
      "Eden Projects is a non-profit organization that works with companies to plant trees and restore forests. They are a certified B-Corp and have planted over 1.5 million trees to date.",
  },
  {
    question: "How is carbon offset calculated?",
    answer:
      "Carbon offset is calculated by multiplying the number of trees planted by 84 kg. This is the average amount of carbon absorbed by a tree in a year.",
  },
  {
    question: "How do I enable the public impact page?",
    answer:
      "Evergreen admins can enable the public impact page from the Settings page.",
  },
];

const features = [
  {
    name: "A bottom-up approach",
    description:
      "Eden starts reforestation projects by connecting with local communities and building relationships with local leaders who want their environment to thrive.",
  },
  {
    name: "Employ to Plant methodology",
    description:
      "Eden provides economic incentives and simple planting techniques to support local communities in restoring their local environment and economy. ",
  },
  {
    name: "Working around the globe",
    description:
      "Every month, trees are planted in local communities across eight countries, producing and protecting tens of millions of trees.",
  },
];

const ImpactReporting = () => {
  const snap = useSnapshot(state);
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;
  let currentOrg = currentTeam;

  if (currentEnterprise) {
    currentOrg = currentEnterprise;
  }

  let domain;
  if (currentOrg) {
    domain = currentOrg.team_domain;
    if (currentOrg.enterprise_name) {
      domain = currentOrg.enterprise_name;
    }
    if (currentOrg.company_name) {
      domain = currentOrg.company_name;
    }
  }

  const co2_offset = currentOrg.total_seeds_planted * 84;

  return (
    <div className="animate-fade">
      <section className="pt-6 pb-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="container mx-auto my-8">
            <div className="flex flex-col">
              <h2 className="text-2xl flex font-semibold text-black leading-relaxed">
                <GlobeIcon
                  className="float-left h-8 w-8 mt-0.5 mr-2"
                  aria-hidden="true"
                />{" "}
                Impact Reporting
              </h2>
              <p className="text-slate-600 mt-2">
                Your organization's global impact with Evergreen
              </p>
            </div>
          </div>
          <div className="mt-6">
            <ImpactDataBoxes seeds_planted={currentOrg.total_seeds_planted} />
          </div>

          <div className="my-6 py-32 bg-backgroundColor bg-bottom lg:bg-[center_top_-70%] shadow rounded-md bg-[url('../public/login/eden-5.jpg')] bg-cover relative">
            <div className="absolute inset-0 flex h-full w-full bg-gradient-to-r rounded-md from-black/0 to-black/80">
              <div className="flex flex-col justify-center items-end mr-16 w-full">
                <h2 className="text-2xl text-right font-semibold text-white">
                  View your
                  <br />
                  public impact page
                </h2>
                <p className="text-white my-2 text-right max-w-xl text-sm">
                  Impact page is a public page that shows your organization's
                  impact with Evergreen. <br /> It can be shared with your team,
                  customers, and partners.
                </p>
                {currentOrg.impact_dash_enabled ? (
                  <Link to={`/impact/${domain}`} target="_blank">
                    <button className="px-6 py-2 mt-4 bg-white rounded-md text-black font font-semibold">
                      Open impact page
                    </button>
                  </Link>
                ) : (
                  <p className="text-white mt-4 text-right max-w-xl text-sm">
                    Public impact page is currently disabled. Admins can enable
                    the impact page from <Link to="/settings">settings</Link>.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="bg-slate-50 shadow rounded-md my-6 text-black ">
            <div className="mx-auto p-10 py-8">
              <div className="mx-auto max-w-2xl lg:max-w-none">
                <div className="text-left">
                  <h2 className="text-xl font-semibold">
                    Carbon offset fun facts
                  </h2>
                  <p className="text-gray-600 text-md">
                    These fun facts equals your organization's total carbon
                    offset of {co2_offset.toLocaleString("fr")} kg.
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl shadow text-center sm:grid-cols-2 lg:grid-cols-4">
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-10">
                    <div className="text-3xl order-first">✈️</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 1140).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading">
                      roundtrips from L.A to NYC
                    </div>
                  </div>
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">🚕</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 3).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      10km taxi rides
                    </div>
                  </div>
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">🐄</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 4562.5).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      cows burping for a year
                    </div>
                  </div>
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">🍔</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 1.81437).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading">
                      hamburgers
                    </div>
                  </div>
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">🌍</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 4900).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      average human's yearly carbon footprint
                    </div>
                  </div>
                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">🇬🇧</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 9660).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      average British's yearly carbon footprint
                    </div>
                  </div>

                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-10">
                    <div className="text-3xl order-first">🇺🇸</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 19780).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      average American's yearly carbon footprint
                    </div>
                  </div>

                  <div className="flex flex-col items-center content-center justify-center bg-white shadow text-slate-800 p-8">
                    <div className="text-3xl order-first">✈️</div>
                    <div className="text-5xl font-semibold tracking-tight">
                      {formatNumber((co2_offset / 80).toFixed(0))}
                    </div>
                    <div className="text-md text-gray-600 leading-6">
                      roundtrips from London to Paris
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="p-8 bg-slate-50 mt-6 rounded-md shadow">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <h1 className="mt-2 text-3xl font-bold tracking-tightsm:text-4xl">
                We partner with Eden Projects
              </h1>
              <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-slate-700 lg:max-w-none lg:grid-cols-2">
                <div>
                  <p>
                    Eden Projects works with committed villages and national
                    leaders to restore forests through the planting of native
                    tree species. They hire and train locals to plant the trees,
                    providing job opportunities and benefiting the community.
                    This approach ensures a high seedling survival rate of over
                    80%, and the villages’ special interest in the forests’
                    survival leads to their protection and maintenance.
                  </p>
                  <p className="mt-8">
                    Eden Projects starts by hiring people in the local villages
                    to plant trees. This gives them a consistent income so they
                    can provide for their families. As the reforestation effort
                    goes on, healthy forests begin to emerge, and the harmful
                    effects of deforestation begin to disappear.
                  </p>
                </div>
                <div>
                  <p>
                    Eden has planted more than 900 million trees around the
                    world since they started, while also working with local
                    communities to restore forests on a massive scale. Through
                    their efforts, they not only create jobs and protect
                    ecosystems but also help mitigate climate change.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden pt-16">
              <div className="mx-auto w-full shadow">
                <img
                  className="mb-[-12%] rounded-md shadow-2xl"
                  src="/login/eden-1.jpg"
                  alt="Eden Projects"
                />
                <div className="relative shadow" aria-hidden="true">
                  <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[15%]" />
                </div>
              </div>
            </div>
            <div className="mx-auto mt-12">
              <dl className="mx-auto grid grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-black sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="inline font-semibold text-black">
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="shadow p-8 bg-slate-50 rounded-md mt-6 w-full text-black">
            <div className="w-full">
              <h2 className="text-xl font-semibold">
                Frequently asked questions
              </h2>
              <dl className="space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-start justify-between text-left">
                            <span className="text-base font-semibold leading-7">
                              {faq.question}
                            </span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base leading-7 text-gray-600">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default ImpactReporting;
