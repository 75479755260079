import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { state, useSnapshot } from "../../state/stateStore";
import RecognitionsTable from "../Common/RecognitionsTable";
import { useReward } from "react-rewards";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = () => {
  const [timeframe, setTimeframe] = useState("TOTAL");
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["🌳"],
    zIndex: 9999999,
    elementCount: 1,
    startVelocity: 15,
    spread: 80,
    elementSize: 45,
  });
  const navigate = useNavigate();

  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;
  const isLoading = snap.isLoading;

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "t") {
        event.preventDefault();
        reward();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  if (!isLoading && currentTeam && currentUser) {
    const recognitions = currentUser.feedback_received_note_history
      .slice()
      .reverse()
      .slice(0, 3);

    let currentOrg = currentTeam;
    if (currentEnterprise) {
      currentOrg = currentEnterprise;
    }
    return (
      <div className="animate-fade">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="relative mt-8 mb-8">
              <div
                className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
                aria-hidden="true"
              ></div>
              <div className="relative">
                <h1 className="text-2xl text-slate-800 font-bold mb-1">
                  Hello {currentUser.user_name} 👋
                </h1>
                <p className="text-mutedTextColor text-md">
                  Welcome back to Evergreen.
                </p>
              </div>
              <span id="rewardId" style={{ zIndex: 99999 }} />
            </div>
          </div>

          <section className="py-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="flow-root">
                <div className="float-left -ml-2">
                  <h1 className="text-xl text-slate-500">Trees</h1>
                </div>
                <div
                  className="inline-flex rounded-md float-right mb-6 -mr-2 -mt-3"
                  role="group"
                >
                  <button
                    type="button"
                    className={classNames(
                      timeframe === "MONTHLY"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor rounded-l-lg border border-gray-200 hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setTimeframe("MONTHLY")}
                  >
                    This month
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      timeframe === "TOTAL"
                        ? "bg-green-300 text-green-800"
                        : "bg-white",
                      "py-2 px-4 text-sm font-medium text-mutedTextColor  rounded-r-lg border border-gray-200 hover:bg-green-300  hover:text-green-800"
                    )}
                    onClick={() => setTimeframe("TOTAL")}
                  >
                    Total
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full lg:w-1/2 lg:pr-4 md:pr-0 pr-0 lg:mb-0 mb-4">
                  <div className="h-full px-10 py-16 bg-gradient-to-l overflow-hidden from-green-400 to-green-500 shadow rounded-md animate-enter transition-all">
                    <div className="relative flex items-center mb-2">
                      <img
                        className="absolute -right-5 z-0 top-20 transform -translate-y-1/3 w-50 sm:w-2/3 mt-10 w-2/3 sm:mt-0"
                        src={process.env.PUBLIC_URL + "/forest.png"}
                        alt="Tree"
                      />

                      <h3 className="mr-auto pr-1 z-10 text-cl font-medium text-white">
                        You have planted{" "}
                      </h3>
                      <div className="px-3 py-1 z-10 text-center bg-green-300 bg-opacity-70 rounded-full">
                        <span className="text-sm font-semibold text-green-800 leading-6">
                          {timeframe === "TOTAL" ? "Total" : "This month"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col z-10 mb-5 mr-72">
                      <span className="inline-block mr-4 text-5xl font-heading text-white font-medium">
                        {timeframe === "TOTAL"
                          ? currentUser.total_trees_planted
                          : currentUser.seeds_planted}{" "}
                        trees
                      </span>
                      <span className="inline-block mr-4 mt-4 text-sm font-medium text-green-800">
                        Your organization has planted{" "}
                        {timeframe === "TOTAL"
                          ? currentOrg.total_seeds_planted
                          : currentOrg.monthly_trees_planted}{" "}
                        trees
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 lg:pl-4 md:pl-0 pl-0 mb-4 lg:mb-0">
                  <div className="h-full px-10 py-16 bg-gradient-to-l overflow-hidden from-amber-400 to-amber-500 shadow rounded-md animate-enter transition-all">
                    <div className="relative flex items-center mb-2">
                      <img
                        className="absolute -right-10 z-0 top-20 transform -translate-y-1/3 w-50 sm:w-2/3 mt-10 w-2/3 sm:mt-0"
                        src={process.env.PUBLIC_URL + "/sun.png"}
                        alt="Sun"
                      />

                      <h3 className="mr-auto pr-1 z-10 text-cl font-medium text-white">
                        Your Carbon Offset
                      </h3>
                      <div className="px-3 py-1 z-10 text-center bg-amber-300 bg-opacity-70 rounded-full">
                        <span className="text-sm font-semibold text-amber-800 leading-6">
                          {timeframe === "TOTAL" ? "Total" : "This month"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col z-10 mb-5 mr-72">
                      <span className="inline-block mr-2 text-5xl font-heading text-white font-medium">
                        {timeframe === "TOTAL"
                          ? currentUser.total_trees_planted * 84
                          : currentUser.seeds_planted * 84}{" "}
                        kg
                      </span>
                      <span className="inline-block mr-4 mt-4 text-sm font-medium text-amber-800">
                        Your organization has a carbon offset of{" "}
                        {timeframe === "TOTAL"
                          ? currentOrg.total_seeds_planted * 84
                          : currentOrg.monthly_trees_planted * 84}{" "}
                        kg
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-4 mt-2">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="flow-root">
                <h1 className="text-xl -ml-2 float-left text-slate-500 mb-6">
                  Recognitions
                </h1>
              </div>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full lg:w-1/2 mb-4 lg:pr-4 md:pr-0 pr-0 lg:mb-0">
                  <div className="h-full px-10 py-16 bg-gradient-to-l overflow-hidden from-violet-200 to-violet-300 shadow rounded-md animate-enter">
                    <div className="relative flex items-center mb-2">
                      <img
                        className="absolute -right-20 z-0 top-5 transform -translate-y-1/3 hidden sm:block sm:w-2/3 mt-10 w-2/3  sm:mt-0"
                        src={process.env.PUBLIC_URL + "/party-popper.png"}
                        alt="Tree"
                      />

                      <h3 className="mr-auto pr-1 z-10 text-cl font-medium text-violet-800">
                        You have received
                      </h3>
                    </div>
                    <div className="flex items-center z-10">
                      <span className="inline-block mr-2 text-3xl font-heading text-violet-800 font-medium">
                        {currentUser.total_feedback_received} recognitions
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 lg:pl-4 md:pl-0 pl-0 mb-4 lg:mb-0">
                  <div className="h-full px-10 py-16 bg-gradient-to-l overflow-hidden from-amber-200 to-amber-300 shadow rounded-md animate-enter">
                    <div className="relative flex items-center mb-2">
                      <img
                        className="absolute -right-16 z-0 top-5 transform -translate-y-1/3 hidden sm:block sm:w-2/3 mt-10 w-1/3 sm:mt-0 sm:mt-0"
                        src={process.env.PUBLIC_URL + "/in-love.png"}
                        alt="Tree"
                      />

                      <h3 className="mr-auto pr-1 z-10 text-cl font-medium text-amber-800">
                        You have given
                      </h3>
                    </div>
                    <div className="flex items-center z-10">
                      <span className="inline-block mr-2 text-3xl font-heading text-amber-800 font-medium">
                        {currentUser.total_feedback_given} recognitions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-4 pb-12 mt-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="flow-root">
                <h1 className="text-xl -ml-2 float-left text-slate-500 mb-6">
                  Latest Recognitions
                </h1>
              </div>
              <div className="sm:-mx-2 mx-2">
                <RecognitionsTable
                  recognitions={recognitions}
                  isCompact={true}
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={() => navigate("/recognitions")}
                  className="flex items-center px-3 py-2 border border-transparent float-right text-sm leading-4 mt-4 font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none"
                >
                  View all
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Dashboard;
