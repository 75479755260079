import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { state, useSnapshot, getViewableUser } from "../../state/stateStore";
import RecognitionsTable from "../Common/RecognitionsTable";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { getProfilePictureURI } from "../Helpers/helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [{ name: "Received recognitions" }, { name: "Sent recognitions" }];

const Profile = () => {
  const [tabIndex, setTabIndex] = useState(0);
  let navigate = useNavigate();
  const params = useParams();
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const viewableUser = snap.viewableUser;
  const isLoading = snap.isLoading;
  const userId = params.userId;

  const changeTab = (event) => {
    setTabIndex(event.target.value);
  };

  useEffect(() => {
    getViewableUser(userId);
  }, [userId]);

  if (!isLoading && currentUser && viewableUser) {
    const received_recognitions = viewableUser.feedback_received_note_history
      .slice()
      .reverse();
    const sent_recognitions = viewableUser.feedback_given_note_history
      .slice()
      .reverse();
    return (
      <>
        <div className="py-6 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex items-center space-x-5 mt-16">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={getProfilePictureURI(viewableUser.profile_pic)}
                    alt={viewableUser.user_name}
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {viewableUser.user_name}
                </h1>
              </div>
              <button
                onClick={() => navigate(-1)}
                className="float-left ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
              >
                <ChevronLeftIcon
                  className="float-left h-4 w-4 mr-1 -ml-1"
                  aria-hidden="true"
                />
                Back
              </button>
            </div>
            <div className="w-full mt-4">
              <div className="mb-4">
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primaryGreen focus:border-primaryGreen sm:text-sm rounded-md"
                    defaultValue={tabIndex}
                    onChange={changeTab}
                  >
                    {tabs.map((tab, i) => (
                      <option key={i} value={i}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab, i) => (
                        <a
                          key={tab.name}
                          className={classNames(
                            tabIndex === i
                              ? "border-primaryGreen text-primaryGreen"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                          )}
                          aria-current={tabIndex === i ? "page" : undefined}
                          onClick={() => setTabIndex(i)}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <RecognitionsTable
                recognitions={
                  tabIndex === 0 ? received_recognitions : sent_recognitions
                }
                navigate={navigate}
                mode={tabIndex === 0 ? "RECEIVED" : "SENT"}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Profile;
