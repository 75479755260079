import axios from "axios";
axios.default.withCredentials = true;

/*                                */
/*      General API routes        */
/*                                */

// Get logged in user data from Evergreen backend

export const getCurrentUser = async () => {
  const url = "/api/user/current";
  return await axios.get(url).then((res, err) => {
    if (res) {
      axios.defaults.headers.post["X-CSRF-Token"] = res.data.csrf;
      axios.defaults.headers.get["X-CSRF-Token"] = res.data.csrf;
      localStorage.setItem("csrf_token", res.data.csrf);
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get viewable user with userId

export const getViewableUser = async (userId) => {
  const url = "/api/user";
  return await axios.post(url, { user_id: userId }).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get Team data from backend

export const getCurrentTeam = async () => {
  const url = "/api/team";
  return await axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get paginated user data from backend for team

export const getPaginatedTeam = async (limit, skip, search, sort) => {
  const url = `/api/team/users`;
  return await axios
    .post(url, { limit: limit, skip: skip, search: search, sort: sort })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

export const getPaginatedTeamForAdmin = async (
  teamId,
  limit,
  skip,
  search,
  sort
) => {
  const url = `/admin/data/team/users`;
  return await axios
    .post(url, {
      team_id: teamId,
      limit: limit,
      skip: skip,
      search: search,
      sort: sort,
    })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

// Get Enterprise data from backend

export const getCurrentEnterprise = async () => {
  const url = "/api/enterprise";
  return await axios.get(url).then((res, err) => {
    if (res) {
      return res.data;
    } else if (err) {
      return null;
    }
  });
};

// Get paginated user data from backend for enterprise

export const getPaginatedEnterprise = async (limit, skip, search, sort) => {
  const url = `/api/enterprise/users`;
  return await axios
    .post(url, { limit: limit, skip: skip, search: search, sort: sort })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

/*                                                  */
/*     Manage users and team settings API           */
/*                                                  */

// ADMIN only: update user roles

export const updateUserRoles = async (action, userId) => {
  const url = `/api/user/update`;
  return await axios
    .post(url, { action: action, user_id: userId })
    .then((res) => {
      return res.data;
    });
};

// ADMIN only: update billing emails for team

export const manageTeamInvoiceEmails = async (action, email) => {
  const url = `/api/team/manage-invoice-emails`;
  return await axios.post(url, { action: action, email: email }).then((res) => {
    return res.data;
  });
};

// ADMIN only: update billing emails for enterprise

export const manageEnterpriseInvoiceEmails = async (action, email) => {
  const url = `/api/enterprise/manage-invoice-emails`;
  return await axios.post(url, { action: action, email: email }).then((res) => {
    return res.data;
  });
};

// SUPER ADMIN only: update user roles for any team

export const updateUserRolesSuperAdmin = async (action, userId, teamId) => {
  const url = `/admin/data/team/users/update`;
  return await axios
    .post(url, { action: action, user_id: userId, team_id: teamId })
    .then((res) => {
      return res.data;
    });
};

export const updateUserSeedsSuperAdmin = async (
  action,
  userId,
  teamId,
  data
) => {
  const url = `/admin/data/team/users/update`;
  return await axios
    .post(url, { action: action, user_id: userId, team_id: teamId, data: data })
    .then((res) => {
      return res;
    });
};

// ADMIN only: update users personal settings

export const updateUserSettings = async (action) => {
  const url = `/api/user/settings`;
  return await axios.post(url, { action: action }).then((res) => {
    return res.data;
  });
};
// Admin only: get paginated user data from backend for team

export const getPaginatedTeamAdmin = async (limit, skip, search, sort) => {
  const url = `/admin/data/team/users`;
  return await axios
    .post(url, { limit: limit, skip: skip, search: search, sort: sort })
    .then((res, err) => {
      if (res) {
        return res.data;
      } else if (err) {
        return null;
      }
    });
};

/*                                */
/*     Impact dashboard           */
/*                                */

//Update impact dashboard visibility for a team
export const toggleTeamImpactDashboard = async () => {
  const url = `/api/team/toggle-impact-dash`;
  return await axios.post(url).then((res) => {
    return res.data;
  });
};

//Update impact dashboard visibility for enterprise
export const toggleEnterpriseImpactDashboard = async () => {
  const url = `/api/enterprise/toggle-impact-dash`;
  return await axios.post(url).then((res) => {
    return res.data;
  });
};

//Update impact statement for a team
export const updateTeamImpactStatement = async (impactStatement) => {
  const url = `/api/team/impact-statement`;
  return await axios
    .post(url, { impact_statement: impactStatement })
    .then((res) => {
      return res.data;
    });
};

//Update impact statement for an enterprise
export const updateEnterpriseImpactStatement = async (impactStatement) => {
  const url = `/api/enterprise/impact-statement`;
  return await axios
    .post(url, { impact_statement: impactStatement })
    .then((res) => {
      return res.data;
    });
};

/*                                */
/*     Company settings           */
/*                                */

//Update enterprise comapany name
export const updateEnterpriseCompanyName = async (companyName) => {
  const url = `/api/enterprise/company-name`;
  return await axios.post(url, { company_name: companyName }).then((res) => {
    return res.data;
  });
};

//Update normal workspace company name
export const updateWorkspaceCompanyName = async (companyName) => {
  const url = `/api/team/company-name`;
  return await axios.post(url, { company_name: companyName }).then((res) => {
    return res.data;
  });
};

/*                                        */
/*     Tags and tag collections API      */
/*                                      */

// Get single team/workspace tag collections

export const getTeamTagCollections = async () => {
  const url = `/api/team/reporting/tag-collections`;
  return await axios.get(url).then((res) => {
    return res.data;
  });
};

// Get enterprise tag collections

export const getEnterpriseTagCollections = async (workspace) => {
  const url = `/api/enterprise/reporting/tag-collections`;
  return await axios
    .post(url, { workspace_id: workspace ? workspace.workspace_id : null })
    .then((res) => {
      return res.data;
    });
};

// Create tag collection

export const createTagCollection = async (collection_name, workspaces) => {
  const url = `/api/tags/tag-collections/create`;
  return await axios
    .post(url, { collection_name: collection_name, workspaces: workspaces })
    .then((res) => {
      return res.data;
    });
};

// Edit tag collection

export const editTagCollection = async (collection_name, collection_id) => {
  const url = `/api/tags/tag-collections/update`;
  return await axios
    .post(url, {
      collection_name: collection_name,
      collection_id: collection_id,
    })
    .then((res) => {
      return res.data;
    });
};

// Disable tag collection
export const disableTagCollection = async (collectionId) => {
  const url = `/api/tags/tag-collections/disable`;
  return await axios.post(url, { collection_id: collectionId }).then((res) => {
    return res.data;
  });
};

// Enable tag collection
export const enableTagCollection = async (collectionId) => {
  const url = `/api/tags/tag-collections/enable`;
  return await axios.post(url, { collection_id: collectionId }).then((res) => {
    return res.data;
  });
};

// Create tag

export const createTag = async (name, emoji, collection_id) => {
  const url = `/api/tags/create`;
  return await axios
    .post(url, { name: name, emoji: emoji, collection_id: collection_id })
    .then((res) => {
      return res.data;
    });
};

// Edit tag

export const editTag = async (tagId, name, emoji) => {
  const url = `/api/tags/update`;
  return await axios
    .post(url, { tag_id: tagId, name: name, emoji: emoji })
    .then((res) => {
      return res.data;
    });
};

// Disable tag
export const disableTag = async (tagId) => {
  const url = `/api/tags/disable`;
  return await axios.post(url, { tag_id: tagId }).then((res) => {
    return res.data;
  });
};

// Enable tag
export const enableTag = async (tagId) => {
  const url = `/api/tags/enable`;
  return await axios.post(url, { tag_id: tagId }).then((res) => {
    return res.data;
  });
};

export const getViewableTag = async (tagId, startDate, endDate) => {
  const url = `/api/tags/view`;
  return await axios
    .post(url, { tag_id: tagId, start_date: startDate, end_date: endDate })
    .then((res) => {
      return res.data;
    });
};

// Get tag recognitions by month and year

export const getTagRecognitionsMonthly = async (month, year, tagId) => {
  const url = `/api/tags/recognitions`;
  return await axios
    .post(url, { month: month, year: year, tag_id: tagId })
    .then((res) => {
      return res.data;
    });
};

/*                                  */
/*      Reporting                  */
/*                                */

// Get team data for date-range based reporting from backend

export const getTeamDataForReporting = async (startDate, endDate) => {
  const url = `/api/team/reporting/date`;
  return await axios
    .post(url, { start_date: startDate, end_date: endDate })
    .then((res) => {
      return res.data;
    });
};

// Get team data for date-range based reporting from backend

export const getEnterpriseDataForReporting = async (
  startDate,
  endDate,
  workspaceId
) => {
  const url = `/api/enterprise/reporting/date`;
  return await axios
    .post(url, {
      start_date: startDate,
      end_date: endDate,
      workspace_id: workspaceId,
    })
    .then((res) => {
      return res.data;
    });
};

// Get data for monthly reporting for a team

export const getTeamDataMonthly = async (data_type, month, year) => {
  const url = `/api/team/reporting/monthly`;
  return await axios
    .post(url, { data_type: data_type, month: month, year: year })
    .then((res) => {
      return res.data;
    });
};

// Get enterprise data for monthly recognitions reporting

export const getEnterpriseDataMonthly = async (
  data_type,
  month,
  year,
  workspaceId
) => {
  const url = `/api/enterprise/reporting/monthly`;
  return await axios
    .post(url, {
      data_type: data_type,
      month: month,
      year: year,
      workspace_id: workspaceId,
    })
    .then((res) => {
      return res.data;
    });
};

// Get single workspace/team members recognition data for a date range, pagination and search

export const getTeamMemberReportingData = async (
  startDate,
  endDate,
  limit,
  skip,
  search,
  sort
) => {
  const url = `/api/team/reporting/users/date`;
  return await axios
    .post(url, {
      start_date: startDate,
      end_date: endDate,
      limit: limit,
      skip: skip,
      search: search,
      sort: sort,
    })
    .then((res) => {
      return res.data;
    });
};

// Get single teams members recognition data for a date range, pagination and search

export const getEnterpriseMemberReportingData = async (
  startDate,
  endDate,
  limit,
  skip,
  search,
  sort,
  workspaceId
) => {
  const url = `/api/enterprise/reporting/users/date`;
  return await axios
    .post(url, {
      start_date: startDate,
      end_date: endDate,
      limit: limit,
      skip: skip,
      search: search,
      sort: sort,
      workspace_id: workspaceId,
    })
    .then((res) => {
      return res.data;
    });
};

export const downloadDataAsCSV = async (
  startDate,
  endDate,
  selectedFields,
  selectedMode,
  workspaceId
) => {
  const url = `/api/download-csv`;
  return await axios
    .post(url, {
      start_date: startDate,
      end_date: endDate,
      selected_fields: selectedFields,
      selected_mode: selectedMode.id,
      workspace_id: workspaceId,
    })
    .then((res) => {
      return res;
    });
};

export const updateTeamTreeEmoji = async (treeEmoji, workspaceId) => {
  const url = `/api/team/custom-tree-emoji`;
  return await axios
    .post(url, {
      custom_tree_emoji: treeEmoji,
      workspace_id: workspaceId,
    })
    .then((res) => {
      return res;
    });
};

export const updatePrivateRecognition = async (status) => {
  const url = `/api/team/private-recognition`;
  return await axios
    .post(url, {
      private_recognition: status,
    })
    .then((res) => {
      return res;
    });
};
