import { useEffect, useState } from "react";
import { state, useSnapshot } from "../../state/stateStore";
import axios from "axios";
import TeamsTable from "./TeamsTable";
import EnterprisesTable from "./EnterprisesTable";

const SuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // State from valtio
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const isLoading = snap.isLoading;

  const getData = async () => {
    await axios
      .get("/admin/data")
      .then((res) => {
        setData(res.data);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
  }, []);

  if (!isLoading && !loading && data && currentUser) {
    return (
      <div className="animate-fade">
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-col">
                <h2 className="text-2xl flex inline-flex font-semibold text-slate-600 leading-relaxed">
                  <img
                    src={process.env.PUBLIC_URL + "/stonksman.png"}
                    className="h-8 w-8 mr-2"
                    alt="Stonksman"
                  />
                  Stonks Super Admin {loading && <LoadingIndicator />}
                </h2>
                <p className="text-slate-600">Stonkey is go Kong.</p>
              </div>
              <section className="py-4">
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-sm text-gray-600 font-heading font-medium">
                          🌳 Trees planted total
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-3xl font-heading font-medium">
                          {data.trees.total_trees_planted}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-sm text-gray-600 font-heading font-medium">
                          🌳 Trees planted this month
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-3xl font-heading font-medium">
                          {data.trees.monthly_trees_planted}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-sm text-gray-600 font-heading font-medium">
                          🏢 Active teams
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-3xl font-heading font-medium">
                          {data.active_teams_count}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                          👨‍💻 Active users
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-3xl font-heading font-medium">
                          {data.users.active_users}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-2">
                <h5 className="text-slate-600 font-semibold py-2">Teams</h5>
                <TeamsTable teams={data.trial_or_sub_teams} />
              </section>
              <section className="py-2">
                <h5 className="text-slate-600 font-semibold py-2">
                  Enterprises
                </h5>
                <EnterprisesTable enterprises={data.trial_or_sub_enterprises} />
              </section>
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoading) {
    return (
      <svg
        role="status"
        className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    );
  }
};

const LoadingIndicator = () => {
  return (
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
};

export default SuperAdmin;
