import React from "react";
import TrialModal from "./TrialModal";

const edenImages = [
  process.env.PUBLIC_URL + "/login/eden-1.jpg",
  process.env.PUBLIC_URL + "/login/eden-2.jpg",
  process.env.PUBLIC_URL + "/login/eden-3.jpg",
  process.env.PUBLIC_URL + "/login/eden-4.jpg",
  process.env.PUBLIC_URL + "/login/eden-5.jpg",
];

const Login = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleLoginWithSlack = () => {
    const authUrl = process.env.REACT_APP_BACKEND_URL + "/auth/slack";
    window.open(authUrl, "_self");
  };
  const handleLoginWithTeams = () => {
    window.open("https://teams.evergreen.so/auth/login", "_self");
  };

  const getRandomImage = () => {
    return edenImages[Math.floor(Math.random() * edenImages.length)];
  };

  // Get a random image from the array
  const randomImage = React.useMemo(() => getRandomImage(), []);

  return (
    <>
      <TrialModal isOpen={showModal} closeModal={() => setShowModal(false)} />
      <div className="flex h-full bg-coolBackground1">
        <div className="flex flex-1 flex-col h-full justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto flex flex-col w-full max-w-sm h-full">
            <div className="flex flex-col grow justify-items-center justify-center">
              <div id="image-container">
                <img
                  className="h-12 w-auto"
                  src={process.env.PUBLIC_URL + "/evergreen-text-logo.svg"}
                  alt="Evergreen"
                />
                <h2 className="mt-6 text-3xl font-semibold font-headline font-semibold text-gray-900">
                  Start feeling good about work
                </h2>
              </div>
              <p className="font-normal text-black mt-4">
                Use your Slack or Teams account to access the dashboard. If you
                don't have an account yet, you can start your{" "}
                <a
                  onClick={() => setShowModal(true)}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium cursor-pointer text-primaryGreen hover:text-primaryGreen hover:underline"
                >
                  14-day free trial
                </a>
                .
              </p>
              <div className="flex-col items-center space-y-4 mt-8">
                <div
                  className="bg-black text-white px-6 py-4 rounded-full justify-center hover:scale-110 transition-all duration-500 flex align-middle items-center font-semibold text-xl cursor-pointer"
                  onClick={() => handleLoginWithSlack()}
                >
                  <img
                    className="h-8 w-8 mr-3"
                    src={process.env.PUBLIC_URL + "/slack-logo.svg"}
                    alt="Evergreen"
                  />
                  <p>Sign in with Slack</p>
                </div>
                <div
                  className="bg-black text-white px-6 py-4 rounded-full justify-center hover:scale-110 transition-all duration-500 flex align-middle items-center font-semibold text-xl cursor-pointer"
                  onClick={() => handleLoginWithTeams()}
                >
                  <img
                    className="h-8 w-8 mr-3"
                    src={process.env.PUBLIC_URL + "/teams-logo.svg"}
                    alt="Evergreen"
                  />
                  Sign in with Teams
                </div>
              </div>
              <p className="text-gray-400 mt-8 text-sm text-center">
                By signing in, you agree to our{" "}
                <a
                  href="https://www.evergreen.so/terms-of-service"
                  target="_blank"
                  className="hover:underline cursor-pointer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.evergreen.so/privacy-policy"
                  target="_blank"
                  className="hover:underline cursor-pointer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            <a
              href="https://evergreen.so/contact"
              className="mt-6 text-gray-400 text-center text-xs flex-shrink-0 hover:underline hover:text-primaryGreen cursor-pointer"
            >
              Trouble signing in?
            </a>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover object-right-bottom"
            src={randomImage}
            alt="Eden Projects"
          />
          <div className="absolute z-50 bottom-5 right-5 rounded-md bg-black bg-opacity-25">
            <div className="text-white/50 text-xs px-2 py-1">
              Image credit:{" "}
              <a
                href="https://edenprojects.org"
                rel="noreferrer"
                target="_blank"
                className="text-white/50 hover:underline"
              >
                Eden Projects
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
