import React, { useState, useEffect, Fragment } from "react";
import Chart from "react-apexcharts";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { getTagRecognitionsByMonth } from "../../../state/stateStore";
import moment from "moment";

const monthsArray = [
  {
    value: 1,
    name: "January",
  },
  {
    value: 2,
    name: "February",
  },
  {
    value: 3,
    name: "March",
  },
  {
    value: 4,
    name: "April",
  },
  {
    value: 5,
    name: "May",
  },
  {
    value: 6,
    name: "June",
  },
  {
    value: 7,
    name: "July",
  },
  {
    value: 8,
    name: "August",
  },
  {
    value: 9,
    name: "September",
  },
  {
    value: 10,
    name: "October",
  },
  {
    value: 11,
    name: "November",
  },
  {
    value: 12,
    name: "December",
  },
];

const TagChart = (props) => {
  const creationDate = props.creationDate;
  const diff = moment().year() - moment(creationDate).year() + 1;
  const years = [...Array(diff).keys()].map(
    (i) => i + moment(creationDate).year()
  );
  const currentMonth = parseInt(moment().format("M"));
  const [months, setMonths] = useState(monthsArray);
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  const [selectedMonth, setSelectedMonth] = useState(months[currentMonth - 1]);

  const getRecognitions = async (month, year) => {
    props.setIsLoading(true);
    await getTagRecognitionsByMonth(month, year, props.tagId);
    props.setIsLoading(false);
  };

  useEffect(() => {
    let creationYear = moment(creationDate).year();
    let creationMonth = moment(creationDate).month();
    let newMonthArray;
    if (selectedYear === moment().year() && selectedYear !== creationYear) {
      newMonthArray = monthsArray.slice(0, currentMonth);
      setMonths(newMonthArray);
    } else if (
      selectedYear === moment().year() &&
      selectedYear === creationYear
    ) {
      newMonthArray = monthsArray.slice(creationMonth, currentMonth);
      setMonths(newMonthArray);
    } else if (
      selectedYear !== moment().year() &&
      selectedYear === creationYear
    ) {
      newMonthArray = monthsArray.slice(creationMonth, monthsArray.length);
      setMonths(newMonthArray);
    } else {
      setMonths(monthsArray);
    }
    getRecognitions(selectedMonth.value, selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    getRecognitions(selectedMonth.value, selectedYear);
  }, [selectedMonth]);

  // Map monthly reocognitions to ApexCharts data //
  let days = [];
  let recognitionsAmount = [];

  props.tagRecognitionsMonthly.map((item) => {
    const day = moment(item.date);
    days.push(day);
    recognitionsAmount.push(item.amount_recognitions);
  });

  // Construct the chart //
  const apexData = {
    options: {
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 0.35,
          },
        },
      },
      colors: ["#ccd1ff"],
      chart: {
        id: "recognitions-bar",
        fontFamily: "Rubik",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
      tooltip: {
        x: {
          show: true,
          formatter: function (value) {
            return value.format("MMMM Do YYYY");
          },
        },
      },
      xaxis: {
        categories: days,
        labels: {
          formatter: function (date) {
            return moment(date).format("D");
          },
        },
      },
      yaxis: {
        opposite: true,
        min: 0,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    },
    series: [
      {
        name: "Daily Recognitions",
        data: recognitionsAmount,
      },
    ],
  };

  return (
    <section className="py-6">
      <div className="container mx-auto">
        <div className="pt-8 pb-8 sm:pb-16 bg-slate-50 shadow rounded-md p-10">
          <div className="flex mb-6 sm:mb-12 flex-wrap justify-between">
            <h3 className="mb-8 sm:mb-0 text-xl font-heading text-slate-600 font-medium leading-8">
              Daily Recognitions
            </h3>
            <div className="w-full flex sm:w-auto">
              <div className="flex px-2 w-1/2">
                <Listbox
                  value={selectedMonth}
                  onChange={setSelectedMonth}
                  className="w-36 block"
                >
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-green-300 focus-visible:ring-offset-2 focus-visible:border-green-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedMonth.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-36 z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {months.map((month) => (
                          <Listbox.Option
                            key={month.value}
                            className={({ active }) =>
                              `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                active
                                  ? "text-green-900 bg-green-100"
                                  : "text-gray-900"
                              }`
                            }
                            value={month}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {month.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <div className="flex px-2 w-1/2">
                <Listbox
                  value={selectedYear}
                  onChange={setSelectedYear}
                  className="w-36"
                >
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-green-300 focus-visible:ring-offset-2 focus-visible:border-green-500 sm:text-sm">
                      <span className="block truncate">{selectedYear}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-36 z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {years.map((year) => (
                          <Listbox.Option
                            key={year}
                            className={({ active }) =>
                              `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                active
                                  ? "text-green-900 bg-green-100"
                                  : "text-gray-900"
                              }`
                            }
                            value={year}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {year}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className="">
            <Chart
              options={apexData.options}
              series={apexData.series}
              type="bar"
              width="100%"
              height="300"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TagChart;
