import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import CountUp from "react-countup";
import StoryDialog from "./StoryDialog";
import moment from "moment";
import Helmet from "react-helmet";
import Lottie from "react-lottie-player";
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  TwitterIcon,
  LinkedinIcon,
  FacebookIcon,
  EmailIcon,
} from "react-share";
import { Mixpanel } from "../Helpers/Mixpanel";
import { LoadingIndicator } from "../Helpers/helpers";
import ImpactGlobe from "./ImpactGlobe";
import { Transition } from "@headlessui/react";
import {
  ArrowDownIcon,
  LocationMarkerIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";
// Lottie animations
import co2 from "../../lotties/co2.json";
import forest from "../../lotties/forest.json";
import paper from "../../lotties/paper.json";

const ImpactDashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStory, setActiveStory] = useState(0);
  const [storyDialogOpen, setStoryDialogOpen] = useState(false);

  const [data, setData] = useState(null);

  let { domain } = useParams();
  let title;
  const shareUrl = window.location.href;

  if (data && data.company_name) {
    title = `${data.company_name} - Our impact through peer-to-peer recognition`;
  } else if (data && !data.company_name) {
    title = `${domain} - Our impact through peer-to-peer recognition`;
  }

  useEffect(() => {
    Mixpanel.track("WEBAPP - Impact dashboard page opened");
  }, []);

  const getData = async () => {
    setIsLoading(true);
    axios
      .get("/api/dashboard/" + domain)
      .then(async (res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  //Get initial data
  useEffect(() => {
    getData();
  }, []);

  let picUrl;
  if (data) {
    if (data.team_pic) {
      picUrl = data.team_pic;
    } else {
      picUrl = data.workspace_pic;
    }
  }

  let workdays = 0;
  // Calculate workdays
  if (data && data.seeds_planted) {
    workdays =
      data.seeds_planted / 100 > 1
        ? (data.seeds_planted / 100).toFixed(0)
        : (1).toFixed(0);
  }

  return (
    <div>
      <Helmet>
        <title>
          {" "}
          {data && data.company_name ? data.company_name : domain} - Our impact
          through peer-to-peer recognition
        </title>
      </Helmet>
      <StoryDialog
        isOpen={storyDialogOpen}
        setOpen={setStoryDialogOpen}
        storyIndex={activeStory}
      />
      <ReactTooltip
        effect="solid"
        textColor="#000000"
        backgroundColor="#FFFFFF"
        borderColor="darkgreen"
        className="tooltip-custom"
      />
      <div className="pt-20 pb-20 bg-backgroundColor border-b-2 border-black">
        {!isLoading && data && data.impact_dash_enabled && (
          <div className="max-w-5xl mx-auto flex items-center justify-center flex-col text-center px-8 sm:px-8 lg:px-0 md:px-24">
            <img src={picUrl} className="rounded-md h-24 mb-6" alt="Company" />
            <h1 className="text-5xl font-semibold font-headline ">
              {data.company_name ? data.company_name : domain}
            </h1>
            <p className="text-xl max-w-5xl mt-6">
              {data.impact_statement
                ? data.impact_statement
                : "Our impact through peer-to-peer recognition."}
            </p>
            <div className="md:grid grid-cols-3 w-full relative auto-rows-max gap-8 mt-12">
              <Transition
                appear
                show={true}
                as="img"
                enter="ease-out duration-1000 delay-500"
                enterFrom="translate-x-24 translate-y-24"
                enterTo="translate-x-0"
                src="/leaf-webpage.svg"
                alt="leaf"
                className="absolute h-36 -mt-16 -ml-12 -rotate-[55deg] hidden md:block"
              ></Transition>
              <Transition
                appear
                show={true}
                as="img"
                enter="ease-out duration-1000"
                enterFrom="translate-x-24"
                enterTo="translate-x-0"
                src="/leaf-webpage.svg"
                alt="leaf"
                className="absolute h-36 -ml-16 -rotate-[85deg] hidden md:block"
              ></Transition>
              <Transition
                appear
                show={true}
                as="img"
                enter="ease-out duration-1000 delay-500"
                enterFrom="-translate-x-24 translate-y-24"
                enterTo="translate-x-0 translate-y-0"
                src="/leaf-webpage.svg"
                alt="leaf"
                className="absolute h-36 -right-0 -top-14 rotate-[20deg] hidden md:block"
              ></Transition>
              <Transition
                appear
                show={true}
                as="img"
                enter="ease-out duration-1000"
                enterFrom="-translate-x-24 translate-y-24"
                enterTo="translate-x-0 translate-y-0"
                src="/leaf-webpage.svg"
                alt="leaf"
                className="absolute h-36 -right-10 -top-10 rotate-[60deg] hidden md:block"
              ></Transition>

              <div className="px-10 w-full py-16 my-4 md:my-0 bg-white z-50 flex-col items-center justify-center border-2 border-black rounded-md">
                <div className="items-center text-center mb-2">
                  <Lottie
                    loop
                    animationData={forest}
                    play
                    style={{ width: 150, height: 150 }}
                    className="mx-auto"
                  />
                  <h3 className="mr-auto text-xl z-10 mt-4 text-black">
                    Total trees planted
                  </h3>
                </div>
                <div className="items-center">
                  <span className="text-5xl font-headline font-semibold text-black">
                    <CountUp
                      end={data.seeds_planted
                        .toFixed(0)
                        .toLocaleString("fr")
                        .replace(",", " ")}
                      duration={3}
                      separator=" "
                    />{" "}
                    trees
                  </span>
                </div>
              </div>
              <div className="px-10 py-16 my-4 md:my-0 bg-white border-2 border-black flex-col items-center justify-center rounded-md">
                <div className="items-center text-center mb-2">
                  <Lottie
                    loop
                    animationData={co2}
                    play
                    style={{ width: 150, height: 150 }}
                    className="mx-auto"
                  />
                  <h3 className="mr-auto z-10 mt-4 text-xl text-black">
                    Total carbon offset
                  </h3>
                </div>
                <div className="items-center">
                  <span className="text-5xl font-headline font-semibold text-black">
                    <CountUp
                      end={(data.seeds_planted * 84)
                        .toFixed(0)
                        .toLocaleString("fr")
                        .replace(",", " ")}
                      duration={3}
                      separator=" "
                    />{" "}
                    kg
                  </span>
                </div>
              </div>

              <div className="px-10 py-16 my-4 md:my-0 z-50 bg-white border-2 border-black flex-col items-center justify-center rounded-md">
                <div className="items-center text-center mb-2">
                  <Lottie
                    loop
                    animationData={paper}
                    play
                    style={{ width: 150, height: 150 }}
                    className="mx-auto"
                  />
                  <h3 className="mr-auto z-10 mt-4 text-xl text-black">
                    Total workdays provided
                  </h3>
                </div>
                <div className="items-center">
                  <span className="text-5xl font-headline font-semibold text-black ">
                    <CountUp end={workdays} duration={3} separator=" " /> days
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center mt-8">
              <LinkedinShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - LinkedIn Share button")
                }
              >
                <LinkedinIcon size={32} round bgStyle={{ fill: "black" }} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Twitter Share button")
                }
              >
                <TwitterIcon size={32} round bgStyle={{ fill: "black" }} />
              </TwitterShareButton>
              <FacebookShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Facebook Share button")
                }
              >
                <FacebookIcon size={32} round bgStyle={{ fill: "black" }} />
              </FacebookShareButton>
              <EmailShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Email Share button")
                }
              >
                <EmailIcon size={32} round bgStyle={{ fill: "black" }} />
              </EmailShareButton>
            </div>
            <h4 className="mt-12 max-w-xl text-sm text-center text-black">
              This page was generated for{" "}
              {data.company_name ? data.company_name : domain} as they are using{" "}
              <a
                href="https://evergreen.so?utm_source=inbound&utm_medium=impact_page"
                target={"_blank"}
                className="hover:underline text-black"
              >
                Evergreen
              </a>{" "}
              to recognize a job well done, while planting trees for the planet.
            </h4>
          </div>
        )}
        {!isLoading && data && !data.impact_dash_enabled && (
          <div className="max-w-7xl mx-auto flex items-center justify-center flex-col text-center">
            <h1 className="text-3xl font-semibold font-headline">
              Impact page statistics have been disabled for this organization.
            </h1>
            <p className="mt-2">
              If you are an Evergreen admin, you can enable the impact page from{" "}
              <Link to="/settings">settings.</Link>
            </p>
          </div>
        )}
        {isLoading && <LoadingIndicator />}
      </div>

      <section className="text-white bg-black  bg-brazil md:bg-right bg-cover">
        <div className="w-full h-full pt-10 pb-10 md:pt-32 md:pb-24 bg-gradient-to-b from-black/40 to-black/90">
          <div className="max-w-4xl mx-auto text-center px-8 md:px-0">
            <h1 className="text-5xl font-headline leading-[4rem] font-semibold text-center animate-fade">
              Planting trees through peer-to-peer recognition
            </h1>
            <p className="text-center mt-10 text-lg max-w-4xl mx-auto">
              At {data && data.company_name ? data.company_name : domain}, we
              use{" "}
              <a
                href="https://evergreen.so?utm_source=inbound&utm_medium=impact_page"
                target={"_blank"}
                className="underline text-white"
              >
                Evergreen
              </a>
              , a peer-to-peer recognition app that partners with{" "}
              <a
                href="https://edenprojects.org/"
                target="_blank"
                className="text-white underline"
              >
                {" "}
                Eden Reforestation Projects
              </a>{" "}
              to plant trees for the planet. Eden is a non-profit organization,
              that has a holistic approach to{" "}
              <span
                data-tip="Reforestation consists in recovering forested areas destroyed in recent times by planting new trees and sowing seeds from tree species"
                className="cursor-pointer underline mr-1"
              >
                reforestation
              </span>
              and
              <span
                data-tip="Ecological restoration aims to recreate, initiate, or accelerate the recovery of an ecosystem that has been disturbed"
                className="cursor-pointer underline ml-1"
              >
                ecological restoration
              </span>
              .
            </p>
            <h2 className="text-2xl font-semibold mt-10">
              Eden Reforestation Projects
            </h2>
            <p className="mt-2 text-lg">
              Eden Projects works with committed villages and national leaders
              to restore forests through the planting of native tree species.
              They hire and train locals to plant the trees, providing job
              opportunities and benefiting the community. This approach ensures
              a high seedling survival rate of over 80%, and the villages’
              special interest in the forests’ survival leads to their
              protection and maintenance.
            </p>
            <h2 className="text-2xl font-semibold mt-8">
              Villages Manage Nurseries and Planting Sites
            </h2>
            <p className="mt-2 text-lg">
              Eden Projects starts by hiring people in the local villages to
              plant trees. This gives them a consistent income so they can
              provide for their families. As the reforestation effort goes on,
              healthy forests begin to emerge, and the harmful effects of
              deforestation begin to disappear.
            </p>
            <h2 className="text-2xl font-semibold mt-8">Incredible Results</h2>
            <p className="mt-2 mb-8 text-lg">
              Eden has planted more than 900 million trees around the world
              since they started, while also working with local communities to
              restore forests on a massive scale. Through their efforts, they
              not only create jobs and protect ecosystems but also help mitigate
              climate change.
            </p>
            <div className="flex-col text-lg items-center pt-8 justify-center text-center">
              <div>Learn where the trees are planted</div>
              <div className="flex items-center justify-center mt-2">
                <ArrowDownIcon className="h-5 w-5 mr-2 animate-bounce" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImpactGlobe />
      <section className="pt-36 pb-36 bg-backgroundColor">
        <div className="max-w-6xl mx-auto flex-col items-center justify-center px-8">
          <h1 className="text-5xl text-center font-headline font-semibold">
            Stories from the community
          </h1>
          <p className="text-center mt-8 text-xl max-w-4xl mx-auto">
            Through peer-to-peer recognition{" "}
            {data && data.company_name ? data.company_name : domain} has
            provided {workdays} workday{workdays === 1 ? "" : "s"} for the
            workers and communities under the Eden Projects. Read stories about
            how employees’ lives have been transformed through reforestation.
          </p>
          <div className="md:grid grid-cols-3 content-center items-center mx-auto justify-center gap-8 mt-20">
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/george.png')] bg-cover cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(0);
                setStoryDialogOpen(true);
              }}
            >
              <div
                className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300
              ease-out 
              "
              >
                <div className="px-6 py-4 self-end  [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Kenya</span>
                  </div>
                  <p className="text-xl mt-2 font-semibold">
                    George’s story: promoting food security through agroforestry
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(0);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/vavy.png')] bg-cover h-full cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(1);
                setStoryDialogOpen(true);
              }}
            >
              <div className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300">
                <div className="px-6 py-4 self-end [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Madagascar</span>
                  </div>
                  <p className="text-xl mt-2 font-semibold">
                    Vavy’s story: the power of economic self-sufficiency
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(1);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/nima.png')] bg-cover h-full cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(2);
                setStoryDialogOpen(true);
              }}
            >
              <div className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300">
                <div className="px-6 py-4 self-end [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Nepal</span>
                  </div>

                  <p className="text-xl mt-2 font-semibold">
                    Nima’s story: overcoming Nepal’s lockdown
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(2);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-28 pb-24 border-t-2 border-black bg-darkerBackgroundColor">
        <div className="max-w-5xl mx-auto flex-col px-8 lg:px-0 md:px-8 sm:px-8 items-center justify-center">
          <h1 className="text-5xl tex-center md:leading-[4rem] font-headline font-semibold text-center">
            Taking part in the United Nations Sustainable Goals for 2030
          </h1>
          <p className="pt-10 text-center pb-10 text-lg">
            By using Evergreen and planting trees through Eden Projects,{" "}
            {data && data.company_name ? data.company_name : domain}{" "}
            participates in achieving the UN Sustainable Development Goals. The
            Sustainable Development Goals are the blueprint to achieve a better
            and more sustainable future for all. They address the global
            challenges we face, including poverty, inequality, climate change,
            environmental degradation, peace and justice.
          </p>
          <div className="flex flex-col text-center md:text-left md:grid grid-cols-4 leading-relaxed md:gap-14 gap-8 pt-14 pb-8">
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-poverty.svg"}
                alt="No Poverty"
              />
              <p className="text-lg font-bold mt-2">GOAL 1</p>
              <p className="text-lg font-bold mt-0.5">No poverty</p>
              <p className="text-md mt-2">
                No poverty. More than 700m people live in extreme poverty. We
                help to employ people to plant trees.
              </p>
            </div>
            <div className="flex flex-col">
              <ChartBarIcon className="w-12 h-12 mx-auto md:m-0" />
              <p className="text-lg font-bold mt-2">GOAL 8</p>
              <p className="text-lg font-bold mt-0.5">Economic Growth</p>
              <p className="text-md mt-2">
                Promoting inclusive and sustainable economic growth, employment
                and decent work for all.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-climate.svg"}
                alt="Climate action"
              />
              <p className="text-lg font-bold mt-2">GOAL 13</p>
              <p className="text-lg font-bold mt-0.5">Climate action</p>
              <p className="text-md mt-2">
                Global emissions of carbon dioxide C02 have increased by almost
                50% since 1990. We need reforestation on a massive scale.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-tree.svg"}
                alt="Plant a tree"
              />
              <p className="text-lg font-bold mt-2">GOAL 15</p>
              <p className="text-lg font-bold mt-0.5">Life on land</p>
              <p className="text-md mt-2">
                Plant a tree and help protect the environment. Forests are home
                to more than 80% of all terrestrial species of animals, plants
                and insects.
              </p>
            </div>
          </div>
          <div className="text-center mt-8 w-full">
            <a
              href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
              target="_blank"
              className="font-bold text-center mt-4 mb-4 text-black text-lg underline"
            >
              See all UN goals
            </a>
          </div>
        </div>
      </section>
      <footer className="bg-backgroundColor border-t-2  border-black">
        <div className="mx-auto max-w-7xl overflow-hidden py-16 px-6 sm:py-12 sm:pt-14 lg:px-8">
          <div className="mb-8 flex justify-center space-x-6">
            <img
              className="h-12 w-auto"
              src={process.env.PUBLIC_URL + "/evergreen-text-logo.svg"}
              alt="Evergreen"
            />
          </div>
          <div className="mb-10 flex justify-center space-x-6">
            <p className="max-w-xl text-center">
              <a
                href="https://evergreen.so?utm_source=inbound&utm_medium=impact_page"
                target={"_blank"}
                className="underline text-black"
              >
                Evergreen
              </a>{" "}
              is the only peer-to-peer recognition software that lets teams
              recognise a job well done, while planting trees for the planet.
              For the ultimate positive, feel-good team culture.
            </p>
          </div>
          {/* <nav className="-mb-6 columns-2 sm:flex sm:justify-center items-center sm:space-x-10">
            <div className="pb-6">
              <a
                href="https://evergreen.so"
                target="_blank"
                className="text-lg leading-6 text-black hover:underline"
              >
                About
              </a>
            </div>
            <div className="pb-6">
              <a
                href="https://www.evergreen.so/esg"
                target="_blank"
                className="text-lg leading-6 text-black hover:underline"
              >
                ESG
              </a>
            </div>
            <div className="pb-6">
              <a
                href="https://www.evergreen.so/case-studies"
                target="_blank"
                className="text-lg leading-6 text-black hover:underline"
              >
                Customers
              </a>
            </div>
            <div className="pb-6">
              <a
                href="https://www.evergreen.so/our-purpose"
                target="_blank"
                className="text-lg leading-6 text-black hover:underline"
              >
                Purpose
              </a>
            </div>
            <div className="pb-6">
              <a
                href="https://www.evergreen.so/schedule-a-demo"
                target="_blank"
                className="text-lg leading-6 text-black hover:underline"
              >
                Schedule a demo
              </a>
            </div>
          </nav> */}
          <div className="mt-8 flex justify-center space-x-4">
            <a
              href="https://twitter.com/AppEvergreen"
              target="_blank"
              className="cursor-pointer h-12 w-12 rounded-full border-2 border-black bg-darkerBackgroundColor flex items-center justify-center p-2"
            >
              <img
                src={process.env.PUBLIC_URL + "/twitter-icon.svg"}
                alt="twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/69009192/"
              target="_blank"
              className="cursor-pointer h-12 w-12 rounded-full border-2 border-black bg-darkerBackgroundColor flex items-center justify-center p-2"
            >
              <img
                src={process.env.PUBLIC_URL + "/linkedin-icon.svg"}
                alt="twitter"
              />
            </a>
          </div>
          <p className="mt-10 text-center text-sm leading-5">
            &copy; {moment().format("YYYY")} Evergreen • Made with 💚 in Finland
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ImpactDashboard;
