/*                                */
/*     State store               */
/*                              */

import { proxy, useSnapshot, subscribe } from "valtio";
import * as api from "../services/api";

const state = proxy({
  currentUser: null,
  currentTeam: null,
  currentEnterprise: null,
  tagCollections: null,
  isEnterprise: false,
  viewableUser: null,
  viewableTag: null,
  viewableTagPreviousPeriod: null,
  viewableTagRecognitionsMonthly: [],
  reportingData30d: null,
  reportingData60d: null,
  reportingDataMonthly: [],
  reportingMemberData: null,
  isLoading: false,
});

const getCurrentUser = async () => {
  state.isLoading = true;
  let data = await api.getCurrentUser();
  if (data.user) {
    state.currentUser = data.user;
    if (data.user.enterprise_id) {
      state.isEnterprise = true;
    }
  } else {
    state.currentUser = null;
  }
  state.isLoading = false;
};

const getCurrentTeam = async () => {
  state.isLoading = true;
  let data = await api.getCurrentTeam();
  if (data.team) {
    state.currentTeam = data.team;
    state.isLoading = false;
  } else {
    state.currentTeam = null;
    state.isLoading = false;
  }
};

const getCurrentEnterprise = async () => {
  state.isLoading = true;
  let data = await api.getCurrentEnterprise();
  if (data.enterprise) {
    state.currentEnterprise = data.enterprise;
    state.isLoading = false;
  } else {
    state.currentEnterprise = null;
    state.isLoading = false;
  }
};

const getViewableUser = async (userId) => {
  state.isLoading = true;
  let data = await api.getViewableUser(userId);
  if (data.viewableUser) {
    state.viewableUser = data.viewableUser;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getViewableTag = async (tagId, startDate, endDate) => {
  state.isLoading = true;
  let data = await api.getViewableTag(tagId, startDate, endDate);
  if (data.viewableTag) {
    state.viewableTag = data.viewableTag;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getViewableTagPreviousPeriod = async (tagId, startDate, endDate) => {
  state.isLoading = true;
  let data = await api.getViewableTag(tagId, startDate, endDate);
  if (data.viewableTag) {
    state.viewableTagPreviousPeriod = data.viewableTag;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const clearViewableTag = () => {
  state.viewableTag = null;
  state.viewableTagPreviousPeriod = null;
};

const getTagCollections = async (workspace) => {
  state.isLoading = true;
  let data;
  if (state.isEnterprise) {
    data = await api.getEnterpriseTagCollections(workspace);
  } else {
    data = await api.getTeamTagCollections();
  }
  if (data) {
    state.tagCollections = data.tag_collections;
    state.isLoading = false;
  } else {
    state.tagCollections = null;
    state.isLoading = false;
  }
};

const getDataReporting = async (
  start_date,
  end_date,
  monthly,
  is_enterprise,
  workspace_id
) => {
  state.isLoading = true;
  let data = null;
  if (is_enterprise && !workspace_id) {
    data = await api.getEnterpriseDataForReporting(start_date, end_date);
  } else if (is_enterprise && workspace_id) {
    data = await api.getEnterpriseDataForReporting(
      start_date,
      end_date,
      workspace_id
    );
  } else {
    data = await api.getTeamDataForReporting(start_date, end_date);
  }
  if (data) {
    if (monthly && monthly === "30d") {
      state.reportingData30d = data;
    } else if (monthly && monthly === "60d") {
      state.reportingData60d = data;
    }
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getDataByMonth = async (
  data_type,
  month,
  year,
  is_enterprise,
  workspace_id
) => {
  state.isLoading = true;
  let data = null;
  if (is_enterprise && !workspace_id) {
    data = await api.getEnterpriseDataMonthly(data_type, month, year);
  } else if (is_enterprise && workspace_id) {
    data = await api.getEnterpriseDataMonthly(
      data_type,
      month,
      year,
      workspace_id
    );
  } else {
    data = await api.getTeamDataMonthly(data_type, month, year);
  }

  if (data) {
    state.reportingDataMonthly = data;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getTagRecognitionsByMonth = async (month, year, tagId) => {
  state.isLoading = true;
  let data = null;
  data = await api.getTagRecognitionsMonthly(month, year, tagId);
  if (data) {
    state.viewableTagRecognitionsMonthly = data;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

const getReportingMemberData = async (
  start_date,
  end_date,
  limit,
  skip,
  search,
  sort,
  is_enterprise,
  workspace_id
) => {
  state.isLoading = true;
  let data = null;

  if (is_enterprise) {
    data = await api.getEnterpriseMemberReportingData(
      start_date,
      end_date,
      limit,
      skip,
      search,
      sort,
      workspace_id ? workspace_id : null
    );
  } else {
    data = await api.getTeamMemberReportingData(
      start_date,
      end_date,
      limit,
      skip,
      search,
      sort
    );
  }
  if (data) {
    state.reportingMemberData = data;
    state.isLoading = false;
  } else {
    state.isLoading = false;
  }
};

export {
  state,
  useSnapshot,
  subscribe,
  getCurrentUser,
  getCurrentTeam,
  getCurrentEnterprise,
  getTagCollections,
  getViewableUser,
  getViewableTag,
  getViewableTagPreviousPeriod,
  clearViewableTag,
  getDataReporting,
  getDataByMonth,
  getTagRecognitionsByMonth,
  getReportingMemberData,
};
