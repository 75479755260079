import CountUp from "react-countup";

const ImpactDataBoxes = (props) => {
  const seeds_planted = props.seeds_planted;
  const co2_offset = seeds_planted * 84;
  const workdays =
    seeds_planted / 100 > 1 ? (seeds_planted / 100).toFixed(0) : (1).toFixed(0);
  return (
    <div className="md:grid grid-cols-3 w-full relative auto-rows-max gap-6">
      <div className="px-10 w-full bg-gradient-to-l pt-10 pb-56 my-4 md:my-0 overflow-hidden from-green-400 to-green-500 shadow rounded-md animate-enter transition-all relative">
        <img
          className="absolute inset -z-1 -bottom-32 h-96 opacity-90"
          src={process.env.PUBLIC_URL + "/forest.png"}
          alt="Tree"
        />
        <div className="text-center mb-2">
          <h3 className="mr-auto text-xl z-10 mt-4 text-white">
            Total trees planted
          </h3>
        </div>
        <div className="items-center text-center">
          <span className="text-4xl font-semibold text-white">
            <CountUp
              end={seeds_planted
                .toFixed(0)
                .toLocaleString("fr")
                .replace(",", " ")}
              duration={3}
              separator=" "
            />{" "}
            trees
          </span>
        </div>
      </div>
      <div className="px-10 w-full bg-gradient-to-l pt-10 pb-56 my-4 md:my-0 overflow-hidden from-amber-400 to-amber-500 shadow rounded-md animate-enter transition-all relative">
        <img
          className="absolute inset -z-1 -bottom-32 scale-110 opacity-90"
          src={process.env.PUBLIC_URL + "/sun.png"}
          alt="Sun"
        />
        <div className="text-center mb-2">
          <h3 className="mr-auto text-xl z-10 mt-4 text-white">
            Total carbon offset
          </h3>
        </div>
        <div className="items-center text-center">
          <span className="text-4xl font-semibold text-white">
            <CountUp
              end={co2_offset.toFixed(0).toLocaleString("fr").replace(",", " ")}
              duration={3}
              separator=" "
            />{" "}
            kg
          </span>
        </div>
      </div>
      <div className="px-10 w-full bg-gradient-to-l pt-10 pb-56 my-4 md:my-0 overflow-hidden from-blue-400 to-blue-500 shadow rounded-md animate-enter transition-all relative">
        <img
          className="absolute inset -z-1 -bottom-20 right-0 h-72 opacity-90"
          src={process.env.PUBLIC_URL + "/wheelbarrow.png"}
          alt="Wheelbarrow"
        />
        <div className="text-center mb-2">
          <h3 className="mr-auto text-xl z-10 mt-4 text-white">
            Total workdays provided
          </h3>
        </div>
        <div className="items-center text-center">
          <span className="text-4xl font-semibold text-white">
            <CountUp end={workdays} duration={3} separator=" " /> days
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImpactDataBoxes;
