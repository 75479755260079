import moment from "moment";
import { Link } from "react-router-dom";

const TrialSubNotification = ({ currentOrg }) => {
  if (currentOrg.trial.is_active) {
    return (
      <Link to="/account">
        <div className="relative bg-yellow-400">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-normal text-white">
                <span className="hidden md:inline">
                  {" "}
                  You have{" "}
                  {moment(currentOrg.trial.start_date)
                    .add(14, "days")
                    .diff(moment(), "days")}{" "}
                  days left in your free trial, click here to upgrade now and
                  start planting real trees 🌳
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  } else if (!currentOrg.subscription.is_active) {
    return (
      <Link to="/account">
        <div className="relative bg-red-700">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-normal text-white">
                <span className="hidden md:inline">
                  Your trial has ended and you have no active subscription,
                  click here to upgrade now and start planting real trees 🌳
                </span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
};

export default TrialSubNotification;
