import Chart from "react-apexcharts";

const StatsChart = ({ monthlyData }) => {
  let treesSeries = [];
  let recognitionSeries = [];
  let activeUsersSeries = [];
  let dates = [];

  monthlyData.map((month) => {
    treesSeries.push(month.trees);
    recognitionSeries.push(month.recognitions);
    activeUsersSeries.push(month.active_users);
    const date = month.date.replace(/-/g, ".");
    dates.push(date);
  });

  const series = [
    {
      name: "Trees",
      data: treesSeries,
    },
    {
      name: "Recognitions",
      data: recognitionSeries,
    },
    {
      name: "Active users",
      data: activeUsersSeries,
    },
  ];

  const options = {
    chart: {
      type: "line",
      fontFamily: "Rubik",
      toolbar: {
        show: true,
      },
    },
    colors: ["#97D8C4", "#4059AD", "#fcd34d"],
    stroke: {
      width: 5,
      curve: "smooth",
    },
    series: series,
    xaxis: {
      tickAmount: 6,
      categories: dates,
    },
  };

  return (
    <section className="py-8">
      <div className="container mx-auto">
        <div className="py-8 bg-slate-50 shadow rounded-md p-10">
          <Chart
            type="line"
            options={options}
            series={series}
            width="100%"
            height={350}
          />
        </div>
      </div>
    </section>
  );
};

export default StatsChart;
