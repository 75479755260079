import { useMemo, useEffect, useState, useCallback } from "react";
import { UserGroupIcon } from "@heroicons/react/outline";
import { useTable, usePagination, useSortBy } from "react-table";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MinusSmIcon,
} from "@heroicons/react/outline";
import { state, useSnapshot } from "../../state/stateStore";
import * as api from "../../services/api";
import { getProfilePictureURI } from "../Helpers/helpers";

const UsersTable = ({
  columns,
  data,
  pageCount: controlledPageCount,
  currentUserId,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["user_id", "profile_pic32"],
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData && fetchData(pageIndex, pageSize, sortBy);
  }, [pageIndex, pageSize, sortBy]);

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-auto divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                      <th className="px-6 py-3.5 text-left text-sm font-medium text-slate-800">
                        <div className="group inline-flex">Actions</div>
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-slate-50"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const isAdmin = row.values.admin;
                    const userId = row.values.user_id;
                    const billable = row.values.is_billable;
                    const picture_uri = getProfilePictureURI(
                      row.values.profile_pic32
                    );
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "user_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={picture_uri}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className={
                                    cell.column.id === "user_name"
                                      ? "ml-4 font-semibold"
                                      : ""
                                  }
                                >
                                  {cell.render("Cell")}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          {isAdmin && currentUserId !== userId && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-red-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRoles(
                                  "UNSET_ADMIN",
                                  userId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Unset as admin
                            </div>
                          )}
                          {!isAdmin && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-green-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRoles("SET_ADMIN", userId);
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Set as admin
                            </div>
                          )}
                          {!billable && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-green-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRoles(
                                  "SET_BILLABLE",
                                  userId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Activate
                            </div>
                          )}
                          {billable && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-red-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRoles(
                                  "UNSET_BILLABLE",
                                  userId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Deactivate
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border border-gray-300 shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ManageUsers = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [pageIndexAndSize, setPageIndexAndSize] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: null,
  });

  const _handleSearch = _.debounce(
    (search) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  const getPaginatedMembers = async (limit, skip, search, sort) => {
    let data;
    if (currentUser.enterprise_id) {
      data = await api.getPaginatedEnterprise(limit, skip, search, sort);
    } else {
      data = await api.getPaginatedTeam(limit, skip, search, sort);
    }

    setData(data.data);
    setPageCount(data.paging.pages);
  };

  const fetchData = useCallback(
    (pageIndex, pageSize, sortBy) => {
      setPageIndexAndSize({ pageIndex, pageSize, sortBy });
      getPaginatedMembers(pageSize, pageSize * pageIndex, searchTerm, sortBy);
    },
    [searchTerm]
  );

  useEffect(() => {
    getPaginatedMembers(10, 0, null, pageIndexAndSize.sortBy);
  }, []);

  useEffect(() => {
    getPaginatedMembers(10, 0, searchTerm, pageIndexAndSize.sortBy);
  }, [searchTerm]);

  // State from valtio
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;

  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "user_id",
    },
    {
      Header: "Picture",
      accessor: "profile_pic32",
    },
    {
      Header: "Name",
      accessor: "user_name",
    },
    {
      Header: "Status",
      accessor: "is_billable",
      Cell: (props) => {
        return (
          <>
            {props.value ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-green-100 text-green-800">
                Active
              </span>
            ) : (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-red-100 text-red-800">
                Inactive
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Admin",
      accessor: "admin",
      Cell: (props) => {
        return (
          <>
            {props.value ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-green-100 text-green-800">
                Admin
              </span>
            ) : null}
          </>
        );
      },
    },
  ]);

  if (!isLoading && currentTeam && data) {
    let orgName = currentTeam.team_domain;
    let currentOrg = currentTeam;

    if (currentEnterprise) {
      orgName = currentEnterprise.enterprise_name;
      currentOrg = currentEnterprise;
    }
    return (
      <>
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-row">
                <h2 className="text-2xl flex float-left inline-flex font-semibold text-slate-600 leading-relaxed">
                  <UserGroupIcon
                    className="float-left h-8 w-8 mt-0.5 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Manage users - {orgName}
                </h2>
                <button
                  onClick={() => navigate(-1)}
                  className="float-left ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  <ChevronLeftIcon
                    className="float-left h-4 w-4 mr-1 -ml-1"
                    aria-hidden="true"
                  />
                  Back
                </button>
              </div>
              <section className="lg:-mt-18">
                <div className="flex mt-2 flex-row w-full">
                  <div className="self-start text-medium text-md mt-4 text-slate-600">
                    <span className="text-bold">{currentOrg.active_users}</span>{" "}
                    active users
                  </div>
                  <div className="ml-5 mt-2 self-end lg:w-1/6">
                    <input
                      id="searchTerm"
                      name="searchTerm"
                      type="text"
                      placeholder="Search by name.."
                      className="w-full text-base md:text-sm bg-white border border-gray-300 rounded-md shadow form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-green-500"
                      onChange={(e) => _handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <UsersTable
                  columns={columns}
                  data={data}
                  currentUserId={currentUser.user_id}
                  fetchData={fetchData}
                  pageCount={pageCount}
                />
              </section>
            </div>
          </div>
        </section>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default ManageUsers;
