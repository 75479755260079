import { useState, useRef, Fragment, useEffect } from "react";
import { Transition, Listbox } from "@headlessui/react";
import {
  OfficeBuildingIcon,
  PhotographIcon,
  PencilAltIcon,
  SaveIcon,
  GlobeIcon,
  SelectorIcon,
  CheckIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";
import * as api from "../../services/api";
import { createToast } from "../Helpers/toaster";
import { getCurrentTeam, getCurrentEnterprise } from "../../state/stateStore";
import UploadPicModal from "./UploadPicModal";

const emojis = [
  {
    emoji: "🌳",
    name: "Deciduous Tree",
    value: "default",
  },
  {
    emoji: "🌲",
    name: "Evergreen Tree",
    value: "pine",
  },
  { emoji: "🌴", name: "Palm Tree", value: "palm" },
  {
    emoji: "🎄",
    name: "Christmas Tree",
    value: "christmas",
  },
];

const Company = ({ currentOrg, currentTeam, isEnterprise }) => {
  const [nameEdit, setNameEdit] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [treeEmoji, setTreeEmoji] = useState("default");
  const [privateRecognition, setPrivateRecognition] = useState(
    currentOrg.private_recognition_enabled
  );
  const ref = useRef(null);

  const closeModal = (notifyUpload) => {
    setIsUploadOpen(false);
    if (notifyUpload) {
      getCurrentTeam();
      getCurrentEnterprise();
      createToast("IMAGE_UPLOADED");
    }
  };

  const openModal = () => {
    setIsUploadOpen(true);
  };

  const updateCompanyName = async () => {
    const companyName = ref.current.value;
    if (companyName) {
      if (isEnterprise) {
        await api.updateEnterpriseCompanyName(companyName);
        await getCurrentEnterprise();
      } else {
        await api.updateWorkspaceCompanyName(companyName);
        await getCurrentTeam();
      }
      createToast("COMPANY_NAME_UPDATED");
      setNameEdit(false);
    }
  };

  const updateTreeEmoji = async (emoji) => {
    await api.updateTeamTreeEmoji(emoji);
    setTreeEmoji(emoji);
    createToast("TREE_EMOJI_UPDATED");
  };

  const updatePrivateRecognition = async (status) => {
    await api.updatePrivateRecognition(status);
    await getCurrentTeam();
    setPrivateRecognition(status);
  };

  const extra_seeds = currentOrg.extra_seeds ? currentOrg.extra_seeds : 0;

  const comp_name = currentOrg.enterprise_name
    ? currentOrg.enterprise_name
    : currentOrg.company_name
    ? currentOrg.company_name
    : currentOrg.team_domain;

  useEffect(() => {
    if (currentOrg.custom_tree_emoji) {
      setTreeEmoji(currentOrg.custom_tree_emoji.tree_emoji);
    }
    if (!isEnterprise) {
      setPrivateRecognition(currentOrg.private_recognition_enabled);
    } else {
      setPrivateRecognition(currentTeam.private_recognition_enabled);
    }
  }, [currentOrg, currentTeam, isEnterprise]);

  return (
    <>
      <UploadPicModal
        isOpen={isUploadOpen}
        closeModal={closeModal}
        getCurrentEnterprise={getCurrentEnterprise}
        getCurrentTeam={getCurrentTeam}
      />
      <div className="mt-4">
        <p className="mt-1 text-sm text-gray-600">
          Modify your company/organization details here.
        </p>

        <ul className="mt-2 divide-y divide-gray-200">
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <OfficeBuildingIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Company name
                </p>
                <p className="text-sm text-gray-600">
                  Your company name, visible on Impact Page.
                </p>
              </div>
            </div>
            <div className="flex basis-1/3 mr-2">
              {!nameEdit && (
                <p className="text-md text-center ml-6 font-semibold">
                  {currentOrg.enterprise_name
                    ? currentOrg.enterprise_name
                    : currentOrg.company_name
                    ? currentOrg.company_name
                    : currentOrg.team_domain}
                </p>
              )}
              {nameEdit && (
                <div className="w-full">
                  <input
                    type="name"
                    name="company-name"
                    id="company-name"
                    ref={ref}
                    className="shadow-sm p-3 focus:ring-primaryGreen focus:border-primaryGreen block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Acme Inc"
                    defaultValue={
                      currentOrg.enterprise_name
                        ? currentOrg.enterprise_name
                        : currentOrg.company_name
                        ? currentOrg.company_name
                        : currentOrg.team_domain
                    }
                  />
                </div>
              )}
            </div>
            <div className="flex">
              {!nameEdit && (
                <button
                  type="button"
                  className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={() => setNameEdit(true)}
                >
                  <PencilAltIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Edit
                </button>
              )}
              {nameEdit && (
                <button
                  type="button"
                  className="inline-flex ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={() => updateCompanyName()}
                >
                  <SaveIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Update
                </button>
              )}
            </div>
          </li>
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <PhotographIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Company picture
                </p>
                <p className="text-sm text-gray-600">
                  Upload your organization picture (logo or similar.) - it will
                  be visible on your Impact page The logo should be 1:1 and
                  maximum of 1000px x 1000px.
                </p>
              </div>
            </div>
            <Transition
              className="flex basis-1/3 mt-4"
              appear={true}
              show={true}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <img
                src={
                  currentOrg.team_pic
                    ? currentOrg.team_pic
                    : currentOrg.workspace_pic
                    ? currentOrg.workspace_pic
                    : process.env.PUBLIC_URL + "/default-placeholder.png"
                }
                className="inline-block h-24 ml-10  rounded-md"
              />
            </Transition>
            <div className="flex">
              <button
                type="button"
                onClick={() => openModal()}
                className="inline-flex whitespace-nowrap ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Upload picture
              </button>
            </div>
          </li>
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <GlobeIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">Seed amount</p>
                <p className="text-sm text-gray-600">
                  You may request extra seeds for your team in addition to the
                  standard 12 seeds per active user per month.
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="text-sm font-medium">
                {12 + extra_seeds} seeds / active user / month
              </p>
            </div>
            <div className="flex">
              <div
                type="button"
                onClick={() =>
                  window.Intercom(
                    "showNewMessage",
                    "I'm interested in extra seeds for my team at " + comp_name
                  )
                }
                className="inline-flex cursor-pointer whitespace-nowrap ml-4 items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded text-orange-700 bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Request seeds
              </div>
            </div>
          </li>
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <PhotographIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Select tree emoji
                </p>
                <p className="text-sm text-gray-600">
                  You can customize your tree emoji. This will be visible inside
                  Slack.
                </p>
              </div>
            </div>

            <div className="flex">
              <Listbox value={treeEmoji} onChange={updateTreeEmoji}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-56 cursor-pointer rounded-md bg-white border border-gray-300 py-2 mt-1 pl-3 pr-10 text-left sm:text-sm">
                    <span className="block truncate">
                      {emojis.find((emoji) => emoji.value === treeEmoji)
                        ? `${
                            emojis.find((emoji) => emoji.value === treeEmoji)
                              .emoji
                          } ${
                            emojis.find((emoji) => emoji.value === treeEmoji)
                              .name
                          }`
                        : "Select tree emoji"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100  "
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="z-50 absolute mt-1 w-56 mr-10 cursor-pointer rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {emojis.map((tree, i) => (
                        <Listbox.Option
                          key={i}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-green-100 text-gree-900"
                                : "text-gray-900"
                            }`
                          }
                          value={tree.value}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {tree.emoji} {tree.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </li>
          <li className="py-4 flex items-center justify-between">
            <div className="flex basis-1/2">
              <div className="flex-shrink-0">
                <EyeOffIcon
                  className="h-6 w-6 mr-3 mt-2 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">
                  Private recognition
                </p>
                <p className="text-sm text-gray-600">
                  Enable private recognition to allow your team members to
                  recognize each other privately. <br />
                </p>
                <small className="mt-2 text-xs text-gray-500">
                  Note that allowing sending private recognitions may affect on
                  the amount of recognition being sent.
                </small>
              </div>
            </div>
            <div className="flex">
              <Listbox
                value={privateRecognition}
                onChange={updatePrivateRecognition}
              >
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-56 cursor-pointer rounded-md bg-white border border-gray-300 py-2 mt-1 pl-3 pr-10 text-left sm:text-sm">
                    <span className="block truncate text-gray-700">
                      {privateRecognition ? "Enabled" : "Disabled"}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-50 mt-1 w-56 mr-10 bg-white rounded-md shadow-lg py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {["Enabled", "Disabled"].map((status, i) => (
                        <Listbox.Option
                          key={i}
                          className={({ active }) =>
                            `cursor-pointer select-none relative py-2 pl-10 pr-4 ${
                              active
                                ? "text-indigo-900 bg-indigo-100"
                                : "text-gray-900"
                            }`
                          }
                          value={status === "Enabled"}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {status}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Company;
