import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  state,
  useSnapshot,
  getCurrentEnterprise,
  getCurrentTeam,
} from "../../state/stateStore";
import {
  MailOpenIcon,
  XCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { LoadingIndicator } from "../Helpers/helpers";
import * as api from "../../services/api";

export default function ManageInvoiceModal({ isOpen, closeModal }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [emails, setEmails] = useState([]);
  const snap = useSnapshot(state);
  const currentEnterprise = snap.currentEnterprise;
  const currentTeam = snap.currentTeam;

  useEffect(() => {
    if (currentEnterprise) {
      setEmails(currentEnterprise.subscription.invoice_emails);
    } else {
      setEmails(currentTeam.subscription.invoice_emails);
    }
  }, [currentTeam, currentEnterprise]);

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isInList = (email) => {
    return emails.includes(email);
  };

  const isValid = (email) => {
    var error = null;

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (error) {
      setError(error);
      return false;
    } else {
      return true;
    }
  };

  const addEmail = async () => {
    if (!isValid(value)) {
      return;
    }

    if (currentEnterprise) {
      await api.manageEnterpriseInvoiceEmails("ADD", value);
      await getCurrentEnterprise();
    } else {
      await api.manageTeamInvoiceEmails("ADD", value);
      await getCurrentTeam();
    }

    setValue("");
    setError(null);
  };

  const removeEmail = async (email) => {
    if (currentEnterprise) {
      await api.manageEnterpriseInvoiceEmails("REMOVE", email);
      await getCurrentEnterprise();
    } else {
      await api.manageTeamInvoiceEmails("REMOVE", email);
      await getCurrentTeam();
    }
  };

  const handleKeyDown = (evt) => {
    if (["Enter"].includes(evt.key)) {
      evt.preventDefault();
      addEmail();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="z-10 overflow-y-auto"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center overflow-y-auto p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-visible rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <MailOpenIcon
                    className="-ml-0.5 mt-0.5 mr-2 h-5 w-5 float-left"
                    aria-hidden="true"
                  />
                  Manage invoice emails
                  <div
                    className="cursor-pointer float-right"
                    onClick={() => closeModal()}
                  >
                    <XCircleIcon className="h-5 w-5" />
                  </div>
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-gray-800">
                    We'll send invoices automatically to the selected email
                    addresses once generated.
                  </p>
                </div>
                <div className="mt-4 flex flex-wrap">
                  {emails.length > 0 &&
                    emails.map((email, index) => (
                      <div
                        key={index}
                        className="bg-slate-200 rounded-full flex items-center text-sm px-3 py-1.5 mx-1 my-1 animate-enter"
                      >
                        {email}
                        <button
                          className="ml-2 hover:text-red-700"
                          onClick={() => {
                            removeEmail(email);
                          }}
                        >
                          <XCircleIcon className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                </div>
                {error && (
                  <div className="mt-4 text-sm text-red-600">{error}</div>
                )}
                <div className="my-4 mt-6 w-full flex">
                  <input
                    value={value}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    type="email"
                    placeholder="Enter email address.."
                    className="w-full text-base md:text-sm bg-white border-gray-300 rounded-l shadow form-input focus:outline-none focus:border-transparent focus:ring-1 focus:ring-offset-1 focus:ring-green-500"
                  />
                  <button
                    className=" bg-green-500 hover:bg-green-600 text-white py-1.5 px-4 text-sm flex items-center rounded-r shadow"
                    onClick={() => {
                      addEmail();
                    }}
                  >
                    <PlusCircleIcon className="h-4 w-4 mr-1" />
                    Add
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
