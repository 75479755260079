import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  state,
  useSnapshot,
  getViewableTag,
  getViewableTagPreviousPeriod,
  getTagRecognitionsByMonth,
  clearViewableTag,
} from "../../../state/stateStore";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import moment from "moment";
import TagStatsBoxes from "./TagStatsBoxes";
import TagChart from "./TagChart";
import TopLists from "./TopLists";
import TopListsModal from "./TopListsModal";
import { LoadingIndicator } from "../../Helpers/helpers";
import LatestRegocnitions from "./LatestRecognitions";
import DateRangeSelector from "../../Common/DateRangeSelector";

const Tag = () => {
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const viewableTag = snap.viewableTag;
  const viewableTagPreviousPeriod = snap.viewableTagPreviousPeriod;
  const viewableTagRecognitionsMonthly = snap.viewableTagRecognitionsMonthly;

  const params = useParams();
  const tagId = params.tagId;

  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment(currentTeam.create_date).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const [showTopListsModal, setShowTopListsModal] = useState(false);
  const [showMode, setShowMode] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getViewableTag(tagId, startDate, endDate);

      const periodLength = moment(endDate).diff(startDate, "days");
      const previousPeriodEndDate = startDate;
      const previousPeriodStartDate = moment(startDate)
        .subtract(periodLength, "days")
        .toDate();

      await getViewableTagPreviousPeriod(
        tagId,
        previousPeriodStartDate,
        previousPeriodEndDate
      );

      await getTagRecognitionsByMonth(
        moment().month() + 1,
        moment().year(),
        tagId
      );
      setIsLoading(false);
    };
    fetchData().catch(console.error);
  }, [startDate, endDate, tagId]);

  const updateDateRange = (dateRange) => {
    setDateRange(dateRange);
  };

  if (
    currentUser &&
    currentTeam &&
    viewableTag &&
    viewableTagPreviousPeriod &&
    viewableTagRecognitionsMonthly
  ) {
    const statsData = {
      tagUsedAmount: {
        current: viewableTag.tagUsedAmount,
        previous: viewableTagPreviousPeriod.tagUsedAmount,
      },
      tagUsagePercentage: {
        current: viewableTag.tagUsagePercentage,
        previous: viewableTagPreviousPeriod.tagUsagePercentage,
      },
      tagRanking: {
        current: viewableTag.tagRanking,
        previous: viewableTagPreviousPeriod.tagRanking,
      },
    };

    return (
      <>
        <div className="py-6 pb-12 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex items-center space-x-5 mt-8 py-4">
              <div>
                <h1 className="text-3xl font-bold text-gray-900">
                  {viewableTag.emoji} {viewableTag.name}
                  <button
                    onClick={() => {
                      navigate(-1);
                      clearViewableTag();
                    }}
                    className=" ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                  >
                    <ChevronLeftIcon
                      className="float-left h-4 w-4 mr-1 -ml-1"
                      aria-hidden="true"
                    />
                    Back
                  </button>
                </h1>
                <p className="text-slate-600 mt-4">
                  Tag related usage data and statistics
                </p>
              </div>
            </div>
            <div className="flex  z-20 sticky top-5 mb-2 rounded-md bg-slate-50 p-4 shadow-md">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                updateDateRange={updateDateRange}
                currentTeam={currentTeam}
              />

              <div className="w-full lg:w-2/6 md:w-full">
                {isLoading && (
                  <div>
                    <LoadingIndicator />
                  </div>
                )}
              </div>
            </div>
            <TagStatsBoxes
              statsData={statsData}
              dateRange={dateRange}
              isLoading={isLoading}
            />
            <TagChart
              tagId={tagId}
              tagName={viewableTag.name}
              setIsLoading={setIsLoading}
              creationDate={currentTeam.create_date}
              tagRecognitionsMonthly={viewableTagRecognitionsMonthly}
            />
            <TopListsModal
              isOpen={showTopListsModal}
              closeModal={() => setShowTopListsModal(false)}
              receivers={viewableTag.receivers}
              senders={viewableTag.senders}
              showMode={showMode}
            />
            <TopLists
              receivers={viewableTag.receivers}
              senders={viewableTag.senders}
              viewMode="small"
              openModal={(showMode) => {
                setShowMode(showMode);
                setShowTopListsModal(true);
              }}
            />
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => setShowTopListsModal(true)}
                className="flex items-center px-3 py-2 border border-transparent float-right text-sm leading-4 font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none"
              >
                View top 50 lists
              </button>
            </div>
            <LatestRegocnitions recognitions={viewableTag.latestRecognitions} />
          </div>
        </div>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Tag;
