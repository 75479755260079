import ReactTooltip from "react-tooltip";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import { LoadingIndicator } from "../../Helpers/helpers";

function capitalize(word) {
  const containsNumbers = /\d/.test(word);
  if (containsNumbers) {
    return word.toUpperCase();
  } else {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
}

const GrowthLabel = ({ growth }) => {
  if (growth > 0) {
    return (
      <div className="inline-flex items-center px-2 py-1 text-center bg-green-200 bg-opacity-70 rounded-full">
        <span className="text-xxs ml-2 font-bold text-green-500 leading-4">
          + {growth} %
        </span>
      </div>
    );
  } else if (growth === 0) {
    return (
      <div className="inline-flex items-center px-2 py-1 text-center bg-yellow-200 bg-opacity-70 rounded-full">
        <span className="text-xxs ml-2 font-bold text-yellow-500 leading-4">
          - %
        </span>
      </div>
    );
  } else if (growth < 0) {
    return (
      <div className="inline-flex items-center px-2 py-1 text-center bg-red-200 bg-opacity-70 rounded-full">
        <span className="text-xxs ml-2 font-bold text-red-500 leading-4">
          {growth} %
        </span>
      </div>
    );
  }
};

const TagStatsBoxes = ({ statsData, dateRange, isLoading }) => {
  if (!dateRange) {
    dateRange = "custom";
  }
  dateRange = capitalize(dateRange);

  let rankingText;

  switch (statsData.tagRanking.current) {
    case 1:
      rankingText = "1st 🥇";
      break;
    case 2:
      rankingText = "2nd 🥈";
      break;
    case 3:
      rankingText = "3rd 🥉";
      break;
    default:
      rankingText = `${statsData.tagRanking.current}th`;
  }

  // Calculate growth percentages

  let usedAmountDifferencePercentage = 0;
  if (statsData.tagUsedAmount.previous > 0) {
    usedAmountDifferencePercentage = (
      ((statsData.tagUsedAmount.current - statsData.tagUsedAmount.previous) /
        statsData.tagUsedAmount.previous) *
      100
    ).toFixed(0);
  }

  let usagePercentageAmountDifferencePercentage = 0;
  if (statsData.tagUsagePercentage.previous > 0) {
    usagePercentageAmountDifferencePercentage = (
      ((statsData.tagUsagePercentage.current -
        statsData.tagUsagePercentage.previous) /
        statsData.tagUsagePercentage.previous) *
      100
    ).toFixed(0);
  }

  return (
    <>
      <ReactTooltip effect="solid" className="rounded-md" />
      <section className="py-6">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <>
                <div className="flex items-center mb-4">
                  <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                    📣 Times used
                    <InformationCircleIcon
                      data-tip="Times this tag has been used in a recognition during the selected time period"
                      className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                      aria-hidden="true"
                    />
                  </h3>
                  <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                    <span className="text-sm font-bold text-blue-500 leading-6">
                      {dateRange}
                    </span>
                  </div>
                </div>
                <div className="flex items-center mb-5">
                  {!isLoading ? (
                    <>
                      <span className="inline-block mr-2 text-2xl font-heading font-medium">
                        {statsData.tagUsedAmount.current} recognition
                        {statsData.tagUsedAmount.current !== 1 && "s"}
                      </span>
                      <div
                        data-tip="Compared to previous period"
                        className="cursor-pointer"
                      >
                        <GrowthLabel growth={usedAmountDifferencePercentage} />
                      </div>
                    </>
                  ) : (
                    <LoadingIndicator />
                  )}
                </div>
              </>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <div className="flex items-center mb-4">
                <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                  📊 Usage %
                  <InformationCircleIcon
                    data-tip="Percentage of recognitions that have used this tag during the selected time period"
                    className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                    aria-hidden="true"
                  />
                </h3>
                <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                  <span className="text-sm font-bold text-blue-500 leading-6">
                    {dateRange}
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-5">
                {!isLoading ? (
                  <>
                    <span className="inline-block mr-2 text-2xl font-heading font-medium">
                      {statsData.tagUsagePercentage.current} %
                    </span>
                    <div
                      data-tip="Compared to previous period"
                      className="cursor-pointer"
                    >
                      <GrowthLabel
                        growth={usagePercentageAmountDifferencePercentage}
                      />
                    </div>
                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-2 mb-4 lg:mb-0">
            <div className="h-full px-8 py-10 bg-slate-50 shadow rounded-md">
              <div className="flex items-center mb-4">
                <h3 className="mr-auto pr-1 text-md text-darkBlueGray-300 font-heading font-medium">
                  🏆 Popularity
                  <InformationCircleIcon
                    data-tip="Ranking of this tag based on usage during the selected time period"
                    className="h-4 w-4 float-right ml-2 cursor-pointer mt-1 text-gray-500"
                    aria-hidden="true"
                  />
                </h3>
                <div className="px-3 py-1 text-center bg-blue-200 bg-opacity-70 rounded-full">
                  <span className="text-sm font-bold text-blue-500 leading-6">
                    {dateRange}
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-5">
                {!isLoading ? (
                  <span className="inline-block mr-2 text-2xl font-heading font-medium">
                    {rankingText}
                  </span>
                ) : (
                  <LoadingIndicator />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TagStatsBoxes;
