import { useTable, usePagination, useSortBy } from "react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MinusSmIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { getProfilePictureURI } from "../Helpers/helpers";

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["team_pic", "_id", "trial"],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-fixed divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const team_pic = row.values.team_pic;
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          let classes =
                            "pt-6 pb-4 pr-3 text-sm sm:pl-6 align-top";
                          return (
                            <td {...cell.getCellProps()} className={classes}>
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "enterprise_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0 mr-4">
                                    <img
                                      className="h-10 w-10 rounded-md"
                                      src={getProfilePictureURI(team_pic)}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span>{cell.render("Cell")}</span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EnterprisesTable = ({ enterprises }) => {
  let navigate = useNavigate();

  const columns = [
    {
      Header: "Id",
      accessor: "_id",
    },
    {
      Header: "Picture",
      accessor: "team_pic",
    },
    {
      Header: "Name",
      accessor: "enterprise_name",
    },
    {
      Header: "Subscription",
      accessor: "subscription.is_active",
      Cell: (props) => {
        console.log(props.row.values);
        return (
          <>
            {props.value ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-normal bg-green-100 text-green-800">
                Susbscribed
              </span>
            ) : props.row.values.trial.is_active ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-normal bg-orange-100 text-orange-800">
                Trialing
              </span>
            ) : (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-normal bg-red-100 text-red-800">
                Not Subscribed
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Recognitions",
      accessor: "total_feedback_given",
    },
    {
      Header: "Trial",
      accessor: "trial",
    },
    {
      Header: "Trees planted",
      accessor: "total_seeds_planted",
    },
    {
      Header: "Workspaces",
      accessor: "workspaces",
      Cell: (props) => {
        return <>{props.value.length} workspaces</>;
      },
    },
  ];
  return (
    <>
      <div>
        <div className="overflow-x-auto lg:-mx-8">
          <div className="w-full py-2 align-middle md:px-6 lg:px-8">
            <Table columns={columns} data={enterprises} navigate={navigate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterprisesTable;
