import toast from "react-hot-toast";
import {
  BellIcon,
  ChartSquareBarIcon,
  DocumentTextIcon,
  OfficeBuildingIcon,
  PhotographIcon,
  TagIcon,
} from "@heroicons/react/outline";

export const createToast = (state) => {
  switch (state) {
    case "SET_REMINDER":
      {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <BellIcon
                    className="h-6 w-6 mt-2 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    Settings updated
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Reminder notifications are now <b>ON</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
      break;
    case "UNSET_REMINDER":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <BellIcon
                      className="h-6 w-6 mt-2 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Reminder notifications are now <b>OFF</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "TOGGLE_IMPACT_ON":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ChartSquareBarIcon
                      className="h-6 w-6 mt-2 text-primaryGreen"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Company settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Impact dashboard is now <b>ON</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "TOGGLE_IMPACT_OFF":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ChartSquareBarIcon
                      className="h-6 w-6 mt-2 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Company settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Impact dashboard is now <b>OFF</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "IMPACT_STATEMENT_UPDATED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <DocumentTextIcon
                      className="h-6 w-6 mt-2 text-gray-900"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Company settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Impact statement has been updated
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "COMPANY_NAME_UPDATED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <OfficeBuildingIcon
                      className="h-6 w-6 mt-2 text-gray-900"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Company settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Company name has been updated
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "TAG_CREATED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <TagIcon
                      className="h-6 w-6 mt-2 text-primaryGreen"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      New tag created
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      New tag has been created succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "TAG_UPDATED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <TagIcon
                      className="h-6 w-6 mt-2 text-primaryGreen"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Tag updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Tag has been updated succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "TAG_DISABLED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <TagIcon
                      className="h-6 w-6 mt-2 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Tag disabled
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Tag has been disabled succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    case "IMAGE_UPLOADED":
      {
        {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <PhotographIcon
                      className="h-6 w-6 mt-2 text-gray-900"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Company settings updated
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Company image has been updated succesfully
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primaryGreen"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      }
      break;
    default:
      break;
  }
};
