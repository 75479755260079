import { Fragment, useRef, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  state,
  useSnapshot,
  getCurrentTeam,
  getCurrentEnterprise,
  getTagCollections,
} from "../../state/stateStore";
import {
  TagIcon,
  CollectionIcon,
  ChevronLeftIcon,
  ArchiveIcon,
  PlusCircleIcon,
  PencilAltIcon,
  DotsHorizontalIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import TagModal from "./TagModal";
import { createToast } from "../Helpers/toaster";
import CollectionModal from "./CollectionModal";
import * as api from "../../services/api";

const ManageTags = () => {
  const navigate = useNavigate();
  let [isTagAddOpen, setIsTagAddOpen] = useState(false);
  let [isCollectionAddOpen, setIsCollectionAddOpen] = useState(false);
  let [activeTagCollection, setActiveTagCollection] = useState(null);
  let [activeTag, setActiveTag] = useState(null);
  let [disableConfirm, setDisableConfirm] = useState(null);
  let [disableCollectionConfirm, setDisableCollectionConfirm] = useState(null);
  let [enableConfirm, setEnableConfirm] = useState(null);
  let [enableCollectionConfirm, setEnableCollectionConfirm] = useState(null);

  useEffect(() => {
    getCurrentTeam();
    getCurrentEnterprise();
    getTagCollections(null);
  }, []);

  const closeAddModal = () => {
    setActiveTag(null);
    setActiveTagCollection(null);
    setIsTagAddOpen(false);
  };

  const closeAddCollectionModal = () => {
    setActiveTagCollection(null);
    setIsCollectionAddOpen(false);
  };

  const openAddModal = (tag, tagCollection) => {
    if (tag) {
      setActiveTag(tag);
    } else {
      setActiveTag(null);
    }
    setActiveTagCollection(tagCollection);
    setIsTagAddOpen(true);
  };

  const openAddCollectionModal = (tagCollection) => {
    setActiveTagCollection(tagCollection);
    setIsCollectionAddOpen(true);
  };

  const enableTag = async (tag) => {
    await api.enableTag(tag._id);
    await getTagCollections();
  };
  const disableTag = async (tag) => {
    await api.disableTag(tag._id);
    await getTagCollections();
  };
  const enableTagCollection = async (tagCollectionId) => {
    await api.enableTagCollection(tagCollectionId);
    await getTagCollections();
  };
  const disableTagCollection = async (tagCollectionId) => {
    await api.disableTagCollection(tagCollectionId);
    await getTagCollections();
  };

  // State from valtio
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;
  const tagCollections = snap.tagCollections;
  const isLoading = snap.isLoading;

  if (currentUser && currentTeam && tagCollections) {
    return (
      <>
        <TagModal
          isOpen={isTagAddOpen}
          tag={activeTag}
          tagCollection={activeTagCollection}
          closeModal={closeAddModal}
        />
        <CollectionModal
          isOpen={isCollectionAddOpen}
          closeModal={closeAddCollectionModal}
          tagCollection={activeTagCollection}
          currentEnterprise={currentEnterprise ? currentEnterprise : null}
        />
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container my-8">
              <div className="flex w-full">
                <h2 className="text-2xl flex float-left font-semibold text-slate-600 leading-relaxed">
                  <TagIcon
                    className="float-left h-8 w-8 mt-1 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Manage Tags
                </h2>
                <button
                  onClick={() => navigate(-1)}
                  className="float-left ml-6 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                >
                  <ChevronLeftIcon
                    className="float-left h-4 w-4 mr-1 -ml-1"
                    aria-hidden="true"
                  />
                  Back
                </button>

                <button
                  onClick={() => openAddCollectionModal(null)}
                  className="float-left ml-4 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparen leading-4 font-medium text-sm rounded-md bg-primaryGreen text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <CollectionIcon
                    className="float-left h-4 w-4 mr-1 -ml-1"
                    aria-hidden="true"
                  />
                  Create new tag collection
                </button>
              </div>
            </div>
            <section className="w-full">
              <div className="flex flex-wrap -mx-2">
                {tagCollections && tagCollections.length === 0 && (
                  <div className="h-full px-6 py-24 bg-slate-50 shadow text-center rounded-md w-full">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tag collections or tags
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new tag collection
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={() => openAddCollectionModal(null)}
                      >
                        Create new tag collection
                      </button>
                    </div>
                  </div>
                )}
                {tagCollections &&
                  tagCollections.map((tagCollection, i) => {
                    return (
                      <div
                        className="w-full lg:w-1/2 px-2 mt-2 mb-4 animate-enter transition-all"
                        key={i}
                      >
                        <div className="h-full py-2 px-2 bg-slate-100 shadow rounded-md">
                          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                              {disableCollectionConfirm !== tagCollection._id &&
                                enableCollectionConfirm !==
                                  tagCollection._id && (
                                  <>
                                    <div className="ml-4 mt-4">
                                      <div className="flex items-center text-lg">
                                        <div className="text-slate-700">
                                          <CollectionIcon
                                            className="float-left h-5 w-5 mt-1 mr-2"
                                            aria-hidden="true"
                                          />{" "}
                                          {tagCollection.collection_name}
                                          {currentEnterprise && (
                                            <div className="text-sm text-gray-500">
                                              {tagCollection.team_name}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0 flex">
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <div>
                                          <Menu.Button className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            <DotsHorizontalIcon className="h-4 w-4 text-black"></DotsHorizontalIcon>
                                          </Menu.Button>
                                        </div>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute z-50 right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                            <div className="px-1 py-1 ">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    className={`${
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    onClick={() =>
                                                      openAddCollectionModal(
                                                        tagCollection
                                                      )
                                                    }
                                                  >
                                                    <PencilAltIcon
                                                      className="mr-2 h-5 w-5 text-gray-500"
                                                      aria-hidden="true"
                                                    />
                                                    Edit
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                            <div className="px-1 py-1">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    className={`${
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    onClick={() => {
                                                      tagCollection.archived
                                                        ? setEnableCollectionConfirm(
                                                            tagCollection._id
                                                          )
                                                        : setDisableCollectionConfirm(
                                                            tagCollection._id
                                                          );
                                                    }}
                                                  >
                                                    {tagCollection.archived ? (
                                                      <RefreshIcon
                                                        className="mr-2 h-5 w-5 text-gray-500"
                                                        aria-hidden="true"
                                                      />
                                                    ) : (
                                                      <ArchiveIcon
                                                        className="mr-2 h-5 w-5 text-gray-500"
                                                        aria-hidden="true"
                                                      />
                                                    )}

                                                    {tagCollection.archived
                                                      ? "Enable"
                                                      : "Archive"}
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </>
                                )}
                              {disableCollectionConfirm ===
                                tagCollection._id && (
                                <div className="ml-4 mt-4 flex-shrink-0 flex">
                                  <>
                                    <span className="text-sm text-slate-800 mt-2 mr-6">
                                      Are you sure you want to archive this tag
                                      collection?
                                    </span>
                                    <div
                                      className="inline-flex px-4 py-2 bg-green-100 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={async () => {
                                        await disableTagCollection(
                                          tagCollection._id
                                        );
                                        setActiveTagCollection(null);
                                        setDisableCollectionConfirm(null);
                                        getTagCollections();
                                      }}
                                    >
                                      I'm sure
                                    </div>
                                    <div
                                      className="inline-flex px-4 py-2 bg-red-100 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={() =>
                                        setDisableCollectionConfirm(null)
                                      }
                                    >
                                      Cancel
                                    </div>
                                  </>
                                </div>
                              )}
                              {enableCollectionConfirm ===
                                tagCollection._id && (
                                <div className="ml-4 mt-4 flex-shrink-0 flex">
                                  <>
                                    <span className="text-sm text-slate-800 mt-2 mr-6">
                                      Are you sure you want to enable this tag
                                      collection?
                                    </span>
                                    <div
                                      className="inline-flex px-4 py-2 bg-green-100 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={async () => {
                                        await enableTagCollection(
                                          tagCollection._id
                                        );
                                        setActiveTagCollection(null);
                                        setEnableCollectionConfirm(null);
                                        getTagCollections();
                                      }}
                                    >
                                      I'm sure
                                    </div>
                                    <div
                                      className="inline-flex px-4 py-2 bg-red-100 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                      onClick={() =>
                                        setEnableCollectionConfirm(null)
                                      }
                                    >
                                      Cancel
                                    </div>
                                  </>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="px-4 pt-4 w-full pb-4">
                            <ul role="list" className="w-full">
                              {tagCollection.tags &&
                                tagCollection.tags.map((tag, i) => {
                                  return (
                                    <li key={i}>
                                      <a
                                        className={`block bg-white ${
                                          tag.archived ? "bg-opacity-30" : ""
                                        } mt-2 shadow border-b border-gray-200 rounded-md w-full`}
                                      >
                                        <div className="px-4 py-4 flex items-center sm:px-6">
                                          {disableConfirm !== tag.id &&
                                            enableConfirm !== tag.id && (
                                              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div className="truncate">
                                                  <div className="flex text-sm">
                                                    <p className="font-medium text-slate-800 truncate">
                                                      {tag.emoji} {tag.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          <div className="ml-5 flex-shrink-0 flex items-center justify-center">
                                            {disableConfirm === tag._id && (
                                              <>
                                                <span className="text-sm text-slate-800 mr-8">
                                                  Are you sure you want to
                                                  archive this tag?
                                                </span>
                                                <div
                                                  className="inline-flex px-4 bg-green-100 py-2 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                  onClick={async () => {
                                                    await disableTag(tag);
                                                    setActiveTag(null);
                                                    setActiveTagCollection(
                                                      null
                                                    );
                                                    setDisableConfirm(null);
                                                    getTagCollections();
                                                  }}
                                                >
                                                  I'm sure
                                                </div>
                                                <div
                                                  className="inline-flex px-4 py-2 bg-red-100 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                  onClick={() =>
                                                    setDisableConfirm(null)
                                                  }
                                                >
                                                  Cancel
                                                </div>
                                              </>
                                            )}
                                            {enableConfirm === tag._id && (
                                              <>
                                                <span className="text-sm text-slate-800 mr-8">
                                                  Are you sure you want to
                                                  enable this tag?
                                                </span>
                                                <div
                                                  className="inline-flex px-4 bg-green-100 py-2 cursor-pointer text-primaryGreen text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                  onClick={async () => {
                                                    await enableTag(tag);
                                                    setActiveTag(null);
                                                    setActiveTagCollection(
                                                      null
                                                    );
                                                    setEnableConfirm(null);
                                                    getTagCollections();
                                                  }}
                                                >
                                                  I'm sure
                                                </div>
                                                <div
                                                  className="inline-flex px-4 py-2 bg-red-100 cursor-pointer text-red-500 text-xs mr-2 items-center justify-center border border-slate-300 rounded-md"
                                                  onClick={() =>
                                                    setEnableConfirm(null)
                                                  }
                                                >
                                                  Cancel
                                                </div>
                                              </>
                                            )}
                                            {disableConfirm !== tag._id &&
                                              enableConfirm !== tag._id && (
                                                <>
                                                  <Menu
                                                    as="div"
                                                    className="relative inline-block text-left"
                                                  >
                                                    <div>
                                                      <Menu.Button className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                                        <DotsHorizontalIcon className="h-4 w-4 text-black"></DotsHorizontalIcon>
                                                      </Menu.Button>
                                                    </div>
                                                    <Transition
                                                      as={Fragment}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95"
                                                    >
                                                      <Menu.Items className="absolute z-50 right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                        <div className="px-1 py-1 ">
                                                          <Menu.Item>
                                                            {({ active }) => (
                                                              <button
                                                                className={`${
                                                                  active
                                                                    ? "bg-gray-100 text-gray-900"
                                                                    : "text-gray-900"
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={() => {
                                                                  openAddModal(
                                                                    tag,
                                                                    tagCollection
                                                                  );
                                                                }}
                                                              >
                                                                <PencilAltIcon
                                                                  className="mr-2 h-5 w-5 text-gray-500"
                                                                  aria-hidden="true"
                                                                />
                                                                Edit
                                                              </button>
                                                            )}
                                                          </Menu.Item>
                                                        </div>
                                                        <div className="px-1 py-1">
                                                          <Menu.Item>
                                                            {({ active }) => (
                                                              <button
                                                                className={`${
                                                                  active
                                                                    ? "bg-gray-100 text-gray-900"
                                                                    : "text-gray-900"
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={() => {
                                                                  tag.archived
                                                                    ? setEnableConfirm(
                                                                        tag._id
                                                                      )
                                                                    : setDisableConfirm(
                                                                        tag._id
                                                                      );
                                                                }}
                                                              >
                                                                {tag.archived ? (
                                                                  <RefreshIcon
                                                                    className="mr-2 h-5 w-5 text-gray-500"
                                                                    aria-hidden="true"
                                                                  />
                                                                ) : (
                                                                  <ArchiveIcon
                                                                    className="mr-2 h-5 w-5 text-gray-500"
                                                                    aria-hidden="true"
                                                                  />
                                                                )}

                                                                {tag.archived
                                                                  ? "Enable"
                                                                  : "Archive"}
                                                              </button>
                                                            )}
                                                          </Menu.Item>
                                                        </div>
                                                      </Menu.Items>
                                                    </Transition>
                                                  </Menu>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                            <div className="w-full flex justify-center mt-4">
                              <button
                                type="button"
                                className="flex items-center px-3 py-2 border border-transparent float-right text-xs leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none"
                                onClick={() =>
                                  openAddModal(null, tagCollection)
                                }
                              >
                                <PlusCircleIcon
                                  className="-ml-1 mr-1 h-3 w-3"
                                  aria-hidden="true"
                                />
                                Add tag to {tagCollection.collection_name}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>
        </section>
      </>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default ManageTags;
