import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import WithNavigation from "../components/Navigation";
import Loading from "../components/Loading";

const ProtectedRoute = ({ children, checkAdmin, checkSuperAdmin }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminAuth, setIsAdminAuth] = useState(false);
  const [isSuperAdminAuth, setIsSuperAdminAuth] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/user/current", { withCredentials: true })
      .then(async (res) => {
        if (res.data.user) {
          setIsLoading(false);
          setIsAuthenticated(true);
          if (res.data.user.admin) {
            setIsAdminAuth(true);
          }
          if (res.data.user.super_admin) {
            setIsSuperAdminAuth(true);
          } else {
            setIsSuperAdminAuth(false);
          }
        } else {
          setIsLoading(false);
          setIsAuthenticated(false);
          setIsAdminAuth(false);
          setIsSuperAdminAuth(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsAuthenticated(false);
        setIsAdminAuth(false);
        setIsSuperAdminAuth(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && !isLoading && !checkAdmin && !checkSuperAdmin) {
    return <WithNavigation>{children}</WithNavigation>;
  }
  if (isAuthenticated && !isLoading && checkAdmin) {
    if (isAdminAuth) {
      return <WithNavigation>{children}</WithNavigation>;
    } else {
      return (
        <WithNavigation>
          <div className="p-10">
            Sorry, you are not authorized as admin user.
          </div>
        </WithNavigation>
      );
    }
  }

  if (isAuthenticated && !isLoading && checkSuperAdmin) {
    if (isSuperAdminAuth) {
      return <WithNavigation>{children}</WithNavigation>;
    } else {
      return (
        <WithNavigation>
          <div className="p-10">
            Sorry, you are not authorized as a super admin user. :sadpepe:
          </div>
        </WithNavigation>
      );
    }
  }
};

export default ProtectedRoute;
