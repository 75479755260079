import { useMemo, useEffect, useState, useCallback, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { getProfilePictureURI } from "../Helpers/helpers";
import { useTable, usePagination, useSortBy } from "react-table";
import _ from "lodash";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MinusSmIcon,
} from "@heroicons/react/outline";
import * as api from "../../services/api";
import { LoadingIndicator } from "../Helpers/helpers";

const UsersTable = ({
  columns,
  data,
  pageCount: controlledPageCount,
  teamId,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["user_id", "profile_pic"],
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData && fetchData(pageIndex, pageSize, sortBy);
  }, [pageIndex, pageSize, sortBy]);

  return (
    <>
      <div className="mt-8 flex flex-col transition-all">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-auto divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}
                            {!column.isSorted && (
                              <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                <MinusSmIcon
                                  className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                      <th className="px-6 py-3.5 text-left text-sm font-medium text-slate-800">
                        <div className="group inline-flex">Actions</div>
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-slate-50"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const isAdmin = row.values.admin;
                    const userId = row.values.user_id;
                    const billable = row.values.is_billable;
                    const picture_uri = getProfilePictureURI(
                      row.values.profile_pic
                    );
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "user_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={picture_uri}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className={
                                    cell.column.id === "user_name"
                                      ? "ml-4 font-semibold"
                                      : ""
                                  }
                                >
                                  {cell.render("Cell")}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          {isAdmin && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-red-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRolesSuperAdmin(
                                  "UNSET_ADMIN",
                                  userId,
                                  teamId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Unset as admin
                            </div>
                          )}
                          {!isAdmin && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-green-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRolesSuperAdmin(
                                  "SET_ADMIN",
                                  userId,
                                  teamId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Set as admin
                            </div>
                          )}
                          {!billable && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-green-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRolesSuperAdmin(
                                  "SET_BILLABLE",
                                  userId,
                                  teamId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Activate
                            </div>
                          )}
                          {billable && (
                            <div
                              className="inline-flex px-4 py-2 text-slate-500 hover:text-red-500 cursor-pointer text-xs mr-2 items-center shadow-sm bg-white justify-center border border-slate-300 rounded-md"
                              onClick={async () => {
                                await api.updateUserRolesSuperAdmin(
                                  "UNSET_BILLABLE",
                                  userId,
                                  teamId
                                );
                                await fetchData(pageIndex, pageSize);
                              }}
                            >
                              Deactivate
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border border-gray-300 shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ManageTeamModal = ({ isOpen, closeModal, team }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [pageIndexAndSize, setPageIndexAndSize] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: null,
  });

  const _handleSearch = _.debounce(
    (search) => {
      setSearchTerm(search);
    },
    500,
    {
      maxWait: 500,
    }
  );

  const getPaginatedMembers = async (limit, skip, search, sort) => {
    setIsLoading(true);
    let data = await api.getPaginatedTeamForAdmin(
      team.team_id,
      limit,
      skip,
      search,
      sort
    );
    setData(data.data);
    setPageCount(data.paging.pages);
    setIsLoading(false);
  };

  const fetchData = useCallback(
    (pageIndex, pageSize, sortBy) => {
      setPageIndexAndSize({ pageIndex, pageSize, sortBy });
      getPaginatedMembers(pageSize, pageSize * pageIndex, searchTerm, sortBy);
    },
    [searchTerm]
  );

  useEffect(() => {
    getPaginatedMembers(10, 0, null, pageIndexAndSize.sortBy);
  }, []);

  useEffect(() => {
    getPaginatedMembers(10, 0, searchTerm, pageIndexAndSize.sortBy);
  }, [searchTerm]);

  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "user_id",
    },
    {
      Header: "Picture",
      accessor: "profile_pic",
    },
    {
      Header: "Name",
      accessor: "user_name",
    },
    {
      Header: "Status",
      accessor: "is_billable",
      Cell: (props) => {
        return (
          <>
            {props.value ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-green-100 text-green-800">
                Active
              </span>
            ) : (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-red-100 text-red-800">
                Inactive
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Admin",
      accessor: "admin",
      Cell: (props) => {
        return (
          <>
            {props.value ? (
              <span className="inline-flex items-center px-2.5 py-0.5 mx-2 mt-1 mb-1 rounded-md text-xs font-medium bg-green-100 text-green-800">
                Admin
              </span>
            ) : null}
          </>
        );
      },
    },
  ]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Manage team admins
                  {isLoading && <LoadingIndicator />}
                  <div
                    className="absolute top-0 right-0 p-4 cursor-pointer"
                    onClick={() => closeModal()}
                  >
                    <XCircleIcon className="h-6 w-6 mt-1" />
                  </div>
                </Dialog.Title>

                <section className="lg:-mt-18">
                  <div className="flex mt-2 flex-row w-full">
                    <div className="mt-2 lg:w-1/6">
                      <input
                        id="searchTerm"
                        name="searchTerm"
                        type="text"
                        placeholder="Search by name.."
                        className="w-full text-base md:text-sm bg-gray-50 border border-gray-300 rounded-md form-input"
                        onChange={(e) => _handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {data && (
                    <UsersTable
                      columns={columns}
                      data={data}
                      teamId={team.team_id}
                      fetchData={fetchData}
                      pageCount={pageCount}
                    />
                  )}
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManageTeamModal;
