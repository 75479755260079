import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { LoadingIndicator } from "../Helpers/helpers";
import * as api from "../../services/api";

export default function ManageSeedseModal({
  isOpen,
  closeModal,
  selectedUser,
}) {
  const [values, setValues] = useState({
    seeds_to_give: selectedUser.seeds_to_give,
    seeds_received: selectedUser.seeds_received,
  });

  const onCloseModal = () => {
    setValues({
      seeds_to_give: selectedUser.seeds_to_give,
      seeds_received: selectedUser.seeds_received,
    });
    closeModal(false);
  };

  const updateSeeds = async () => {
    const data = {
      seeds_to_give: parseInt(values.seeds_to_give),
      seeds_received: parseInt(values.seeds_received),
    };
    const response = await api.updateUserSeedsSuperAdmin(
      "UPDATE_SEEDS",
      selectedUser.user_id,
      selectedUser.team_id,
      data
    );
    if (response.status === 200) {
      alert("Seeds updated successfully for " + selectedUser.user_name);
      onCloseModal();
    } else {
      alert("Something went wrong, please try again later");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="z-10 overflow-y-auto"
        onClose={() => {
          onCloseModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center overflow-y-auto p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-visible rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Manage seeds
                  <div
                    className="cursor-pointer float-right"
                    onClick={() => onCloseModal()}
                  >
                    <XCircleIcon className="h-5 w-5" />
                  </div>
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-gray-800">
                    Set seeds to give and unplanted seeds for a{" "}
                    <b> {selectedUser.user_name}</b>
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-sm text-gray-600">Seeds to give</p>
                  <input
                    type="number"
                    className="w-full border border-gray-300 p-2 rounded-md"
                    placeholder="Seeds to give"
                    min={0}
                    value={values.seeds_to_give}
                    onChange={(e) => {
                      setValues({ ...values, seeds_to_give: e.target.value });
                    }}
                  />
                </div>
                <div className="mt-4">
                  <p className="text-sm text-gray-600">Unplanted seeds</p>
                  <input
                    type="number"
                    className="w-full border border-gray-300 p-2 rounded-md"
                    placeholder="Unplanted seeds"
                    min={0}
                    value={values.seeds_received}
                    onChange={(e) => {
                      setValues({ ...values, seeds_received: e.target.value });
                    }}
                  />
                </div>
                <div className="mt-4">
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      updateSeeds();
                    }}
                  >
                    Update
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
