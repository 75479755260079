import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import StoryDialog from "./StoryDialog";
import Helmet from "react-helmet";
import { Mixpanel } from "../Helpers/Mixpanel";
import { LoadingIndicator } from "../Helpers/helpers";
import { Interweave } from "interweave";
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  TwitterIcon,
  LinkedinIcon,
  FacebookIcon,
  EmailIcon,
} from "react-share";
import {
  ArrowDownIcon,
  LocationMarkerIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";

import ImpactGlobe from "./ImpactGlobe";

const PersonalShare = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStory, setActiveStory] = useState(0);
  const [storyDialogOpen, setStoryDialogOpen] = useState(false);
  const [data, setData] = useState(null);

  let { user } = useParams();

  useEffect(() => {
    Mixpanel.track("WEBAPP - Share page opened");
  }, []);

  const getData = async () => {
    setIsLoading(true);
    axios
      .get("/api/share/" + user)
      .then(async (res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  //Get initial data
  useEffect(() => {
    getData();
  }, []);

  const shareUrl = window.location.href;
  const title = "My trees planted badge - Issued by Evergreen";

  let picUrl = data ? data.trees_planted_badge : null;

  let latestPlant = null;
  if (data && data.plant_history && data.plant_history.length > 0) {
    const [lastItem] = data.plant_history.slice(-1);
    latestPlant = lastItem.date;
  }

  let firstText;

  if (data && data.company_name) {
    firstText =
      "To earn this badge " +
      data.user_name +
      " has done exceptional work and has received recognition from their colleagues while working at <a href='" +
      process.env.PUBLIC_URL +
      "/impact/" +
      data.company_name +
      "' target='_blank' class='text-black underline'> " +
      data.company_name +
      "</a>.";
  } else if (data) {
    firstText =
      "To earn this badge " +
      data.user_name +
      " has done exceptional work and has received recognition from their colleagues.";
  }

  let workdaysString = "has helped";
  // Calculate workdays
  console.log(data);
  if (data && data.impact_dash_enabled) {
    let workdays =
      data.company_trees_planted / 100 > 1
        ? (data.company_trees_planted / 100).toFixed(0)
        : (1).toFixed(0);
    workdaysString = "has provided " + workdays + "  workdays for";
  }

  return (
    <div>
      <StoryDialog
        isOpen={storyDialogOpen}
        setOpen={setStoryDialogOpen}
        storyIndex={activeStory}
      />

      <div className="py-12 bg-backgroundColor border-b-2 border-black">
        {!isLoading && data && latestPlant && (
          <div className="max-w-xl mx-auto flex items-center justify-center flex-col text-center">
            <Helmet>
              <title>
                Trees Planted Badge - {data.user_name}'s impact through
                peer-to-peer recognition
              </title>
            </Helmet>
            <img
              src="/evergreen-logo-icon-green.png"
              alt="Evergreen"
              className="mx-auto w-8 text-center mb-4"
            />
            <h1 className="text-3xl font-semibold font-headline font-semibold">
              Trees Planted Badge
            </h1>
            <p className="text-md mt-3">
              Issued by{" "}
              <a
                href="https://www.evergreen.so/?utm_source=inbound&utm_medium=share_badge&utm_campaign=trees_planted"
                target="_blank"
                rel="noreferrer"
                className="text-black font-bold underline"
              >
                Evergreen
              </a>
            </p>
            <h4 className="mt-4 text-lg text-gray-800">
              This badge was issued to <b>{data.user_name}</b> on{" "}
              {moment(latestPlant).format("MMMM Do, YYYY")}
            </h4>
            <p className="mt-3 text-md text-gray-800">
              <Interweave content={firstText} /> {data.user_name} has decided to
              make an impact to this world by planting {data.trees_planted}{" "}
              trees to help in reforestation and ecological restoration.
            </p>
            <img
              src={picUrl}
              className="rounded-md shadow-sm mt-8"
              style={{ maxWidth: 600 }}
              alt={
                data.user_name + "has planted " + data.trees_planted + " trees!"
              }
            />
            <div className="text-center mt-6">
              <p className="text-sm text-gray-600 my-2">Share this badge</p>
              <LinkedinShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - LinkedIn Share button")
                }
              >
                <LinkedinIcon size={32} round bgStyle={{ fill: "black" }} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Twitter Share button")
                }
              >
                <TwitterIcon size={32} round bgStyle={{ fill: "black" }} />
              </TwitterShareButton>
              <FacebookShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Facebook Share button")
                }
              >
                <FacebookIcon size={32} round bgStyle={{ fill: "black" }} />
              </FacebookShareButton>
              <EmailShareButton
                url={shareUrl}
                className="ml-1"
                onShareWindowClose={() =>
                  Mixpanel.track("WEBAPP - Email Share button")
                }
              >
                <EmailIcon size={32} round bgStyle={{ fill: "black" }} />
              </EmailShareButton>
            </div>
          </div>
        )}
        {!isLoading && data && !latestPlant && (
          <div className="max-w-xl mx-auto flex items-center justify-center flex-col text-center">
            <img
              src="/evergreen-logo-icon-green.png"
              alt="Evergreen"
              className="mx-auto w-8 text-center mb-4"
            />
            <h1 className="text-3xl font-semibold font-headline font-semibold">
              Trees Planted Badge
            </h1>
            <p className="mt-3 text-md text-gray-800">
              <b>{data.user_name}</b> has not been issued a badge yet. To start
              planting trees and earning badges, give your colleagues
              recognition for their work.
            </p>
          </div>
        )}
        {isLoading && <LoadingIndicator />}
      </div>
      <section className="text-white bg-black  bg-brazil md:bg-right bg-cover">
        <div className="w-full h-full pt-10 pb-10 md:pt-32 md:pb-24 bg-gradient-to-b from-black/40 to-black/90">
          <div className="max-w-4xl mx-auto text-center px-8 md:px-0">
            <h1 className="text-5xl font-headline leading-[4rem] font-semibold text-center animate-fade">
              Planting trees through peer-to-peer recognition
            </h1>
            <p className="text-center mt-10 text-lg max-w-4xl mx-auto">
              At{" "}
              {data && data.company_name
                ? data.company_name
                : data
                ? data.user_name + "'s workplace"
                : ""}{" "}
              , we use{" "}
              <a
                href="https://evergreen.so?utm_source=inbound&utm_medium=impact_page"
                target={"_blank"}
                className="underline text-white"
              >
                Evergreen
              </a>
              , a peer-to-peer recognition app that partners with{" "}
              <a
                href="https://edenprojects.org/"
                target="_blank"
                className="text-white underline"
              >
                {" "}
                Eden Reforestation Projects
              </a>{" "}
              to plant trees for the planet. Eden is a non-profit organization,
              that has a holistic approach to{" "}
              <span
                data-tip="Reforestation consists in recovering forested areas destroyed in recent times by planting new trees and sowing seeds from tree species"
                className="cursor-pointer underline mr-1"
              >
                reforestation
              </span>
              and
              <span
                data-tip="Ecological restoration aims to recreate, initiate, or accelerate the recovery of an ecosystem that has been disturbed"
                className="cursor-pointer underline ml-1"
              >
                ecological restoration
              </span>
              .
            </p>
            <h2 className="text-2xl font-semibold mt-10">
              Eden Reforestation Projects
            </h2>
            <p className="mt-2 text-lg">
              Eden Projects works with committed villages and national leaders
              to restore forests through the planting of native tree species.
              They hire and train locals to plant the trees, providing job
              opportunities and benefiting the community. This approach ensures
              a high seedling survival rate of over 80%, and the villages’
              special interest in the forests’ survival leads to their
              protection and maintenance.
            </p>
            <h2 className="text-2xl font-semibold mt-8">
              Villages Manage Nurseries and Planting Sites
            </h2>
            <p className="mt-2 text-lg">
              Eden Projects starts by hiring people in the local villages to
              plant trees. This gives them a consistent income so they can
              provide for their families. As the reforestation effort goes on,
              healthy forests begin to emerge, and the harmful effects of
              deforestation begin to disappear.
            </p>
            <h2 className="text-2xl font-semibold mt-8">Incredible Results</h2>
            <p className="mt-2 mb-8 text-lg">
              Eden has planted more than 900 million trees around the world
              since they started, while also working with local communities to
              restore forests on a massive scale. Through their efforts, they
              not only create jobs and protect ecosystems but also help mitigate
              climate change.
            </p>
            <div className="flex-col text-lg items-center pt-8 justify-center text-center">
              <div>Learn where the trees are planted</div>
              <div className="flex items-center justify-center mt-2">
                <ArrowDownIcon className="h-5 w-5 mr-2 animate-bounce" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImpactGlobe />
      <section className="pt-36 pb-36 bg-backgroundColor">
        <div className="max-w-6xl mx-auto flex-col items-center justify-center px-8">
          <h1 className="text-5xl text-center font-headline font-semibold">
            Stories from the community
          </h1>
          <p className="text-center mt-8 text-xl max-w-4xl mx-auto">
            Through peer-to-peer recognition{" "}
            {data && data.company_name
              ? data.company_name
              : data
              ? data.user_name + "'s workplace"
              : ""}{" "}
            {workdaysString} the workers and communities under the Eden
            Projects. Read stories about how employees’ lives have been
            transformed through reforestation.
          </p>
          <div className="md:grid grid-cols-3 content-center items-center mx-auto justify-center gap-8 mt-20">
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/george.png')] bg-cover cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(0);
                setStoryDialogOpen(true);
              }}
            >
              <div
                className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300
              ease-out 
              "
              >
                <div className="px-6 py-4 self-end  [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Kenya</span>
                  </div>
                  <p className="text-xl mt-2 font-semibold">
                    George’s story: promoting food security through agroforestry
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(0);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/vavy.png')] bg-cover h-full cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(1);
                setStoryDialogOpen(true);
              }}
            >
              <div className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300">
                <div className="px-6 py-4 self-end [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Madagascar</span>
                  </div>
                  <p className="text-xl mt-2 font-semibold">
                    Vavy’s story: the power of economic self-sufficiency
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(1);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-md overflow-hidden h-[28rem] my-4 md:my-0 bg-[url('../public/stories/nima.png')] bg-cover h-full cursor-pointer group transition-all duration-300 hover:scale-105 hover:shadow-sm text-white"
              onClick={() => {
                setActiveStory(2);
                setStoryDialogOpen(true);
              }}
            >
              <div className="h-full w-full bg-gradient-to-b flex items-end from-black/10 to-black/80  transition-all duration-300">
                <div className="px-6 py-4 self-end [@media(hover:hover)]:translate-y-4 transition-all duration-300 [@media(hover:hover)]:group-hover:-translate-y-3">
                  <div className="text-sm flex items-center">
                    <LocationMarkerIcon className="h-4 w-4 mr-1" />
                    <span className="mt-0.5">Nepal</span>
                  </div>

                  <p className="text-xl mt-2 font-semibold">
                    Nima’s story: overcoming Nepal’s lockdown
                  </p>
                  <div
                    className="mt-2 underline cursor-pointer opacity-0 transition-all duration-300 group-hover:animate-enter group-hover:opacity-100"
                    onClick={() => {
                      setActiveStory(2);
                      setStoryDialogOpen(true);
                    }}
                  >
                    Read
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-28 pb-24 border-t-2 border-black bg-darkerBackgroundColor">
        <div className="max-w-5xl mx-auto flex-col px-8 lg:px-0 md:px-8 sm:px-8 items-center justify-center">
          <h1 className="text-5xl tex-center md:leading-[4rem] font-headline font-semibold text-center">
            Taking part in the United Nations Sustainable Goals for 2030
          </h1>
          <p className="pt-10 text-center pb-10 text-lg">
            By using Evergreen and planting trees through Eden Projects,{" "}
            {data && data.company_name
              ? data.company_name
              : data
              ? data.user_name + "'s workplace"
              : ""}{" "}
            participates in achieving the UN Sustainable Development Goals. The
            Sustainable Development Goals are the blueprint to achieve a better
            and more sustainable future for all. They address the global
            challenges we face, including poverty, inequality, climate change,
            environmental degradation, peace and justice.
          </p>
          <div className="flex flex-col text-center md:text-left md:grid grid-cols-4 leading-relaxed md:gap-14 gap-8 pt-14 pb-8">
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-poverty.svg"}
                alt="No Poverty"
              />
              <p className="text-lg font-bold mt-2">GOAL 1</p>
              <p className="text-lg font-bold mt-0.5">No poverty</p>
              <p className="text-md mt-2">
                No poverty. More than 700m people live in extreme poverty. We
                help to employ people to plant trees.
              </p>
            </div>
            <div className="flex flex-col">
              <ChartBarIcon className="w-12 h-12 mx-auto md:m-0" />
              <p className="text-lg font-bold mt-2">GOAL 8</p>
              <p className="text-lg font-bold mt-0.5">Economic Growth</p>
              <p className="text-md mt-2">
                Promoting inclusive and sustainable economic growth, employment
                and decent work for all.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-climate.svg"}
                alt="Climate action"
              />
              <p className="text-lg font-bold mt-2">GOAL 13</p>
              <p className="text-lg font-bold mt-0.5">Climate action</p>
              <p className="text-md mt-2">
                Global emissions of carbon dioxide C02 have increased by almost
                50% since 1990. We need reforestation on a massive scale.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                className="w-12 h-12 mx-auto md:m-0"
                src={process.env.PUBLIC_URL + "/icon-tree.svg"}
                alt="Plant a tree"
              />
              <p className="text-lg font-bold mt-2">GOAL 15</p>
              <p className="text-lg font-bold mt-0.5">Life on land</p>
              <p className="text-md mt-2">
                Plant a tree and help protect the environment. Forests are home
                to more than 80% of all terrestrial species of animals, plants
                and insects.
              </p>
            </div>
          </div>
          <div className="text-center mt-8 w-full">
            <a
              href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
              target="_blank"
              className="font-bold text-center mt-4 mb-4 text-black text-lg underline"
            >
              See all UN goals
            </a>
          </div>
        </div>
      </section>
      <footer className="bg-backgroundColor border-t-2  border-black">
        <div className="mx-auto max-w-7xl overflow-hidden py-16 px-6 sm:py-12 sm:pt-14 lg:px-8">
          <div className="mb-8 flex justify-center space-x-6">
            <img
              className="h-12 w-auto"
              src={process.env.PUBLIC_URL + "/evergreen-text-logo.svg"}
              alt="Evergreen"
            />
          </div>
          <div className="mb-10 flex justify-center space-x-6">
            <p className="max-w-xl text-center">
              <a
                href="https://evergreen.so?utm_source=inbound&utm_medium=impact_page"
                target={"_blank"}
                className="underline text-black"
              >
                Evergreen
              </a>{" "}
              is the only peer-to-peer recognition software that lets teams
              recognise a job well done, while planting trees for the planet.
              For the ultimate positive, feel-good team culture.
            </p>
          </div>
          <div className="mt-8 flex justify-center space-x-4">
            <a
              href="https://twitter.com/AppEvergreen"
              target="_blank"
              className="cursor-pointer h-12 w-12 rounded-full border-2 border-black bg-darkerBackgroundColor flex items-center justify-center p-2"
            >
              <img
                src={process.env.PUBLIC_URL + "/twitter-icon.svg"}
                alt="twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/69009192/"
              target="_blank"
              className="cursor-pointer h-12 w-12 rounded-full border-2 border-black bg-darkerBackgroundColor flex items-center justify-center p-2"
            >
              <img
                src={process.env.PUBLIC_URL + "/linkedin-icon.svg"}
                alt="twitter"
              />
            </a>
          </div>
          <p className="mt-10 text-center text-sm leading-5">
            &copy; {moment().format("YYYY")} Evergreen • Made with 💚 in Finland
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PersonalShare;
