import { getProfilePictureURI } from "../../Helpers/helpers";
import { ArrowRightIcon } from "@heroicons/react/outline";
import moment from "moment";

const LatestRegocnitions = ({ recognitions }) => {
  return (
    <div className="flex flex-col mt-12 px-8 py-8 bg-slate-50 shadow rounded-md">
      <div className="w-full mb-4">
        <h3 className="mr-auto text-lg text-darkBlueGray-300 font-normal">
          📣 Latest recognitions
        </h3>
        <p className="text-sm text-gray-500">
          Latest recognitions for the selected tag
        </p>
      </div>

      <div className="w-full">
        {recognitions && recognitions.length > 0 && (
          <ul>
            {recognitions.map((recognition, i) => (
              <li
                key={i}
                className="flex my-4 border p-5 rounded-lg border-gray-300 bg-white"
              >
                <div className="flex w-56 items-center">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={getProfilePictureURI(recognition.sender_profile_pic)}
                    alt={recognition.sender_name}
                  />
                  <div className="ml-3 flex">
                    <p className="text-md font-normal text-gray-900">
                      {recognition.sender_name}
                    </p>
                  </div>
                </div>

                <div className="justify-center mr-6 items-center text-center flex">
                  <ArrowRightIcon
                    className="h-6 w-6 mx-auto text-gray-400"
                    aria-hidden="true"
                  />
                </div>

                <div className="flex w-56 items-center">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={getProfilePictureURI(recognition.receiver.profile_pic)}
                    alt={recognition.receiver.name}
                  />
                  <div className="ml-3 flex">
                    <p className="text-md font-normal text-gray-900">
                      {recognition.receiver.name}
                    </p>
                  </div>
                </div>
                <div className="ml-12 w-1/2 items-center flex">
                  <p className="text-md font-normal text-gray-600">
                    {recognition.note}
                  </p>
                </div>
                <div className="ml-12 w-32 items-center flex">
                  <p className="text-sm font-normal text-gray-600">
                    {recognition.amount_seeds} x 🌱
                  </p>
                </div>
                <div className="mr-6 w-32 items-center flex">
                  <p className="text-sm text-gray-600">
                    {moment(recognition.date).format("DD.MM.YYYY")}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}
        {recognitions.length === 0 && (
          <p className="text-sm text-center border-2 border-dotted border-gray-400 rounded-md py-8 text-gray-500">
            No recognitions for this tag yet.
          </p>
        )}
      </div>
    </div>
  );
};

export default LatestRegocnitions;
