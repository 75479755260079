import { useEffect, useState } from "react";
import { state, useSnapshot } from "../../state/stateStore";
import { CreditCardIcon, MailOpenIcon } from "@heroicons/react/outline";
import { UserGroupIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import ManageInvoiceModal from "./ManageInvoiceModal";
import moment from "moment";
import axios from "axios";

const LoadingIndicator = () => {
  return (
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
};

const Account = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [receipts, setReceipts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [manageInvoiceModalOpen, setManageInvoiceModalOpen] = useState(false);

  // Create checkout session to stripe
  const createCheckoutSession = async () => {
    await axios
      .post("/billing/create-checkout-session")
      .then((res) => {
        stripe.redirectToCheckout({
          sessionId: res.data.sessionId,
        });
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  const getUpcomingInvoice = async () => {
    await axios
      .get("/billing/upcoming-invoice")
      .then((res) => {
        setUpcomingInvoice(res.data.invoice);
      })
      .catch((error, res) => {
        setUpcomingInvoice(null);
        console.log(error);
      });
  };

  const getReceipts = async () => {
    await axios
      .get("/billing/receipts")
      .then((res) => {
        setReceipts(res.data.receipts);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getUpcomingInvoice();
      await getReceipts();
      setLoading(false);
    };
    getData();
  }, []);

  // State from valtio
  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;
  const isLoading = snap.isLoading;

  if (currentUser && currentTeam) {
    let orgName = currentTeam.team_domain;
    let currentOrg = currentTeam;

    if (currentEnterprise) {
      orgName = currentEnterprise.enterprise_name;
      currentOrg = currentEnterprise;
    }

    return (
      <div className="animate-fade">
        <ManageInvoiceModal
          isOpen={manageInvoiceModalOpen}
          closeModal={() => setManageInvoiceModalOpen(false)}
        />
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-col">
                <h2 className="text-2xl flex font-semibold text-black leading-relaxed">
                  <CreditCardIcon
                    className="float-left h-8 w-8 mt-0.5 mr-2"
                    aria-hidden="true"
                  />{" "}
                  Account
                </h2>
                <p className="text-slate-600">
                  Manage your users and Evergreen subscription.
                </p>
              </div>
              <section className="py-8">
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full lg:w-1/3 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                          Organization
                        </h3>
                      </div>
                      <div className="flex items-center mb-1">
                        <span className="inline-block mr-2 text-2xl font-heading font-medium">
                          {orgName}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="inline-block mr-2 mt-2 text-xl font-normal">
                          {currentOrg.active_users} active users{" "}
                          {currentEnterprise && (
                            <span>
                              in {currentEnterprise.workspaces.length}{" "}
                              workspaces
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="flex">
                        <button
                          className="float-left inline-flex mt-7 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={() => navigate("/account/users/manage")}
                        >
                          <UserGroupIcon
                            className="float-left h-4 w-4 -mt-0.5 mr-2"
                            aria-hidden="true"
                          />{" "}
                          Manage users
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/3 mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 mr-10 flex-col shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                          Subscription
                        </h3>
                      </div>
                      <div className="flex flex-col mb-1">
                        {currentOrg.subscription.is_active &&
                          !currentOrg.trial.is_active &&
                          !currentOrg.subscription.to_cancel && (
                            <>
                              <div className="block mr-2 text-2xl font-heading text-primaryGreen font-medium">
                                Evergreen 🌱
                              </div>
                              <div className="block mr-2 text-md mt-2 font-normal">
                                Thank you for subscribing. 💚 Your plan is now
                                active.
                              </div>
                            </>
                          )}
                        {currentOrg.subscription.is_active &&
                          !currentOrg.trial.is_active &&
                          currentOrg.subscription.to_cancel && (
                            <>
                              <div className="block mr-2 text-2xl font-heading text-orange-500 font-medium">
                                To be cancelled
                              </div>
                              <div className="block mr-2 text-md mt-2 font-normal">
                                Your subscription will be cancelled on next
                                period end.
                              </div>
                            </>
                          )}
                        {!currentOrg.subscription.is_active &&
                          currentOrg.trial.is_active && (
                            <>
                              <div className="inline-block mr-2 text-2xl font-heading text-yellow-400 font-medium">
                                Trial
                              </div>
                              <div className="block mr-2 text-md mt-2 font-normal">
                                Please subscribe to start planting trees. 🌳
                              </div>
                            </>
                          )}
                        {!currentOrg.subscription.is_active &&
                          !currentOrg.trial.is_active && (
                            <>
                              <div className="block mr-2 text-2xl font-heading text-orange-700 font-medium">
                                No subscription
                              </div>
                              <div className="block mr-2 text-md mt-2 font-normal">
                                Your trial period has ended or you have
                                unsubscribed. Thank you fo trying out Evergreen,
                                please subscribe to continue planting trees. 🌳
                              </div>
                            </>
                          )}
                      </div>
                      {currentOrg.subscription.is_active &&
                        !currentOrg.trial.is_active && (
                          <div className="flex self-end">
                            <form
                              method="POST"
                              action="/billing/create-customer-portal"
                            >
                              <button
                                className="float-left inline-flex mt-5 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                type="submit"
                              >
                                <CreditCardIcon
                                  className="float-left h-4 w-4 -mt-0.5 mr-2"
                                  aria-hidden="true"
                                />{" "}
                                Manage billing
                              </button>
                            </form>
                          </div>
                        )}
                      {!currentOrg.subscription.is_active && (
                        <div className="flex self-end">
                          <button
                            className="float-left inline-flex mt-5 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => createCheckoutSession()}
                          >
                            <CreditCardIcon
                              className="float-left h-4 w-4 -mt-0.5 mr-2"
                              aria-hidden="true"
                            />{" "}
                            Subscribe
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {!currentOrg.trial.is_active &&
                    currentOrg.subscription.is_active && (
                      <div className="w-full lg:w-1/3 mb-4 lg:mb-0">
                        <div className="h-full px-8 py-8 bg-slate-50 mr-10 shadow flex-col rounded-md">
                          <div className="flex items-center mb-4">
                            <h3 className="mr-auto pr-1 text-md text-gray-600 font-heading font-medium">
                              Upcoming invoice amount
                            </h3>
                          </div>
                          <div className="flex items-center mb-1">
                            <span className="inline-block mr-2 text-2xl font-heading font-medium">
                              {upcomingInvoice ? (
                                <div>
                                  {(upcomingInvoice.total / 100).toFixed(2)} $
                                </div>
                              ) : loading ? (
                                <LoadingIndicator />
                              ) : (
                                <div>No upcoming invoice</div>
                              )}{" "}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="inline-block mr-2 mt-2 text-md font-normal">
                              The price of your subscription is based on your
                              team's active Evergreen users. Manage your users
                              from manage users -page.
                            </span>
                          </div>
                          <div className="flex self-stretch items-center">
                            <button
                              className="items-center self-end mt-4 px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              onClick={() => setManageInvoiceModalOpen(true)}
                            >
                              <MailOpenIcon
                                className="float-left h-4 w-4 -mt-0.5 mr-2"
                                aria-hidden="true"
                              />{" "}
                              Invoice emails
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </section>
              {currentOrg.subscription.is_active && (
                <section className="py-8 -mx-2">
                  <div className="w-full mb-4 lg:mb-0">
                    <div className="h-full px-8 py-8 bg-slate-50 shadow rounded-md">
                      <div className="flex items-center mb-4">
                        <h3 className="mr-auto text-md text-gray-600 font-heading font-medium">
                          Latest invoices
                        </h3>
                      </div>
                      <div className="mt-4 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Amount paid
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Hosted invoice
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    PDF invoice
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                                {!receipts && (
                                  <div className="my-8 flex items-center content-center w-full text-center">
                                    <div className="flex align-center justify-center">
                                      <LoadingIndicator />
                                    </div>
                                  </div>
                                )}
                                {receipts &&
                                  receipts.map((invoice) => (
                                    <tr key={invoice.created}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 md:pl-0">
                                        {moment(invoice.period_start)
                                          .isValid && (
                                          <p>
                                            {moment
                                              .unix(invoice.created)
                                              .format("MMMM Do YYYY")}
                                          </p>
                                        )}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm">
                                        <p>{invoice.amount_paid / 100} $</p>
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-3 text-sm ">
                                        <a
                                          href={invoice.hosted_invoice_url}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="text-primaryGreen hover:text-green-700 cursor-pointer"
                                        >
                                          View Invoice
                                        </a>
                                      </td>
                                      <td className="whitespace-nowrap text-sm sm:pr-6 md:pr-0">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = `${invoice.invoice_pdf}`;
                                          }}
                                          className="inline-flex items-center px-3 py-2 my-2 border border-transparent text-xs cursor-pointer font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none"
                                        >
                                          Download PDF
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <div className="flex justify-center align-center pt-6 border-t border-gray-200">
                              <form
                                method="POST"
                                action="/billing/create-customer-portal"
                              >
                                <button
                                  type="submit"
                                  className="flex font-medium text-sm cursor-pointer text-primaryGreen hover:text-green-700"
                                >
                                  View invoice history
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  } else if (isLoading) {
    <svg
      role="status"
      className="w-10 h-10 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>;
  }
};

export default Account;
