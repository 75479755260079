import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  getCurrentUser,
  getCurrentEnterprise,
  getCurrentTeam,
  getTagCollections,
} from "./state/stateStore";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import ProtectedRoute from "./auth/ProtectedRoute";

// Import pages
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ImpactDashboard from "./components/Impact/ImpactDashboard";
import ImpactReporting from "./components/Impact/ImpactReporting";
import PersonalShare from "./components/Impact/PersonalShare";
import Recognitions from "./components/Recognitions/Recognitions";
import Reporting from "./components/Reporting/Reporting";
import Account from "./components/Account/Account";
import CheckoutComplete from "./components/Account/CheckoutComplete";
import ManageUsers from "./components/Account/ManageUsers";
import Tags from "./components/Tags/Tags";
import ManageTags from "./components/Tags/Manage";
import NotInstalled from "./components/NotInstalled";
import Settings from "./components/Settings/Settings";
import Profile from "./components/Profile/Profile";
import Tag from "./components/Tags/TagPage/Tag";
import SuperAdmin from "./components/SuperAdmin/SuperAdmin";
import TeamPage from "./components/SuperAdmin/TeamPage";

const App = () => {
  useEffect(() => {
    const fetchData = async () => {
      await getCurrentUser();
      await getCurrentTeam();
      await getCurrentEnterprise();
      await getTagCollections();
    };
    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/notinst" element={<NotInstalled />} />
        <Route path="/impact/:domain" element={<ImpactDashboard />} />
        <Route
          path="/impact/reporting"
          element={
            <ProtectedRoute>
              <ImpactReporting />
            </ProtectedRoute>
          }
        />
        <Route path="/share/:user" element={<PersonalShare />} />
        <Route
          index
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/recognitions"
          element={
            <ProtectedRoute>
              <Recognitions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reporting"
          element={
            <ProtectedRoute>
              <Reporting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tags/manage"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ManageTags />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tags"
          element={
            <ProtectedRoute>
              <Tags />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute checkAdmin={true}>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/checkout-complete"
          element={
            <ProtectedRoute>
              <CheckoutComplete />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account/users/manage"
          element={
            <ProtectedRoute checkAdmin={true}>
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tag/:tagId"
          element={
            <ProtectedRoute>
              <Tag />
            </ProtectedRoute>
          }
        />
        <Route
          path="/superadmin"
          element={
            <ProtectedRoute checkSuperAdmin={true}>
              <SuperAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/superadmin/team/:teamId"
          element={
            <ProtectedRoute checkSuperAdmin={true}>
              <TeamPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
      <Toaster position="top-right" />
    </BrowserRouter>
  );
};

export default App;
