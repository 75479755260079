import { Fragment, useState } from "react";
import { Dialog, Listbox, Transition, RadioGroup } from "@headlessui/react";
import { state, useSnapshot } from "../../state/stateStore";
import {
  DocumentDownloadIcon,
  CheckIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { LoadingIndicator } from "../Helpers/helpers";
import DateRangeSelector from "../Common/DateRangeSelector";
import * as api from "../../services/api";

const modes = [
  {
    id: 1,
    name: "User statistics",
    description: "Download per user statistics",
  },
  {
    id: 2,
    name: "Recognitions",
    description: "Download all recognitions within the selected time period",
  },
];

export default function DownloadCSVModal({ isOpen, closeModal, isEnterprise }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const [selectedFields, setSelectedFields] = useState([
    { id: 0, name: "User name", value: "user_name", isChecked: true },
    { id: 1, name: "User email", value: "user_email", isChecked: true },
    {
      id: 2,
      name: "Recognition given amount",
      value: "feedback_given",
      isChecked: true,
    },
    {
      id: 3,
      name: "Recognition received amount",
      value: "feedback_received",
      isChecked: true,
    },
    { id: 4, name: "Seed amount", value: "seeds_given", isChecked: true },
    { id: 5, name: "Trees planted", value: "trees_planted", isChecked: true },
    { id: 6, name: "Carbon offset", value: "carbon_offset", isChecked: true },
    {
      id: 8,
      name: "Recognition given notes",
      value: "recognition_given_notes",
      isChecked: true,
    },
    {
      id: 9,
      name: "Recognition received notes",
      value: "recognition_received_notes",
      isChecked: true,
    },
  ]);

  const [selectedFieldsRecognition, setSelectedFieldsRecognition] = useState([
    {
      id: 0,
      name: "Recognition date",
      value: "recognition_date",
      isChecked: true,
    },
    { id: 1, name: "Sender email", value: "sender_email", isChecked: true },
    { id: 2, name: "Sender name", value: "sender_name", isChecked: true },
    { id: 3, name: "Receiver name", value: "receiver_name", isChecked: true },
    {
      id: 4,
      name: "Recognition note",
      value: "recognition_note",
      isChecked: true,
    },
    { id: 5, name: "Seed amount", value: "seed_amount", isChecked: true },
    { id: 6, name: "Tags", value: "tags", isChecked: true },
  ]);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [selectedMode, setSelectedMode] = useState(modes[0]);
  const [endDate, setEndDate] = useState(new Date());

  const snap = useSnapshot(state);
  const currentEnterprise = snap.currentEnterprise;
  const currentTeam = snap.currentTeam;

  const updateSelected = (fieldId) => {
    setSelectedFields((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === fieldId) {
          return { ...obj, isChecked: !obj.isChecked };
        }
        return obj;
      });
      return newState;
    });
  };

  const updateSelectedRecognition = (fieldId) => {
    setSelectedFieldsRecognition((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === fieldId) {
          return { ...obj, isChecked: !obj.isChecked };
        }
        return obj;
      });
      return newState;
    });
  };

  const downloadData = async () => {
    setIsLoading(true);
    try {
      const response = await api.downloadDataAsCSV(
        startDate,
        endDate,
        selectedMode === modes[0] ? selectedFields : selectedFieldsRecognition,
        selectedMode,
        selectedWorkspace ? selectedWorkspace.workspace_id : null
      );
      if (response.status === 200) {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "evergreen-export.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        closeModal();
      }
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong or there is no data to download");
      closeModal();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="z-10 overflow-y-auto"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center overflow-y-auto p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-visible rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <DocumentDownloadIcon
                    className="-ml-0.5 mt-0.5 mr-2 h-5 w-5 float-left"
                    aria-hidden="true"
                  />
                  Download reporting data
                </Dialog.Title>
                <div className="mt-3">
                  <p className="text-sm text-gray-500">
                    Download your company Evergreen reporting data as a CSV
                    file. This might take a few minutes to download.
                  </p>
                  {!isLoading && (
                    <div className="mb-4 mt-4">
                      {isEnterprise && (
                        <>
                          <h5 className="text-sm font-medium mb-2 leading-relaxed">
                            Select workspace
                          </h5>
                          {currentEnterprise && (
                            <Listbox
                              value={selectedWorkspace}
                              onChange={setSelectedWorkspace}
                            >
                              <div className="mt-1 mb-4">
                                <Listbox.Button className="relative w-56 cursor-pointer rounded-md bg-white border border-gray-300 py-2 mt-1 pl-3 pr-10 text-left sm:text-sm">
                                  <span className="block truncate">
                                    {" "}
                                    {selectedWorkspace
                                      ? selectedWorkspace.workspace_name
                                      : "Select workspace"}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <SelectorIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="z-50 absolute mt-1 w-56 mr-10 cursor-pointer rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <Listbox.Option
                                      key={1}
                                      className={({ active }) =>
                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-green-100 text-gree-900"
                                            : "text-gray-900"
                                        }`
                                      }
                                      value={{
                                        id: 1,
                                        workspace_name:
                                          currentEnterprise.enterprise_name,
                                        workspace_id: null,
                                        whole_enterprise: true,
                                      }}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {currentEnterprise.enterprise_name}{" "}
                                            (all workspaces)
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>

                                    {currentEnterprise.workspaces.map(
                                      (workspace, i) => (
                                        <Listbox.Option
                                          key={workspace.workspace_id}
                                          className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-green-100 text-gree-900"
                                                : "text-gray-900"
                                            }`
                                          }
                                          value={{
                                            id: workspace._id,
                                            workspace_name:
                                              workspace.workspace_name,
                                            workspace_id:
                                              workspace.workspace_id,
                                            whole_enterprise: false,
                                          }}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {workspace.workspace_name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          )}
                        </>
                      )}
                      <h5 className="text-sm font-medium mb-2 leading-relaxed">
                        Select date range
                      </h5>
                      <div className="w-full">
                        <DateRangeSelector
                          startDate={startDate}
                          endDate={endDate}
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                          currentTeam={currentTeam}
                        />
                      </div>
                      <h5 className="text-sm mt-4 font-medium mb-2 leading-relaxed">
                        Select downloadable data
                      </h5>
                      <div className="mx-auto w-full">
                        <RadioGroup
                          value={selectedMode}
                          onChange={setSelectedMode}
                        >
                          <RadioGroup.Label className="sr-only">
                            Select what to download
                          </RadioGroup.Label>
                          <div className="space-x-4 flex">
                            {modes.map((mode) => (
                              <RadioGroup.Option
                                key={mode.name}
                                value={mode}
                                className={({ active, checked }) =>
                                  `${
                                    active
                                      ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-primaryGreen"
                                      : ""
                                  }
                  ${checked ? "bg-primaryGreen text-black" : "bg-white"}
                    relative flex cursor-pointer w-1/2 rounded-md border botder-gray-200 px-5 py-4 shadow-md focus:outline-none`
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex w-full items-center justify-between">
                                      <div className="flex items-center">
                                        <div className="text-sm">
                                          <RadioGroup.Label
                                            as="p"
                                            className={`font-medium  ${
                                              checked
                                                ? "text-white"
                                                : "text-gray-900"
                                            }`}
                                          >
                                            {mode.name}
                                          </RadioGroup.Label>
                                          <RadioGroup.Description
                                            as="span"
                                            className={`inline ${
                                              checked
                                                ? "text-gray-50"
                                                : "text-gray-600"
                                            }`}
                                          >
                                            {mode.description}
                                          </RadioGroup.Description>
                                        </div>
                                      </div>
                                      {checked && (
                                        <div className="shrink-0 text-white">
                                          <CheckIcon className="h-6 w-6" />
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                      <Transition
                        show={selectedMode === modes[0]}
                        enter="transition-opacity duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <h5 className="text-sm font-medium mb-2 mt-6 leading-relaxed">
                          Select fields to display
                        </h5>
                        <fieldset className="space-y-5 grid grid-cols-2">
                          <legend className="sr-only">Fields</legend>
                          {selectedFields.map((field, index) => (
                            <div
                              className="relative flex items-start"
                              key={index}
                            >
                              <div className="flex items-center h-5">
                                <input
                                  id="username"
                                  name="username"
                                  checked={field.isChecked}
                                  onChange={() => {
                                    updateSelected(index);
                                  }}
                                  type="checkbox"
                                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="sender"
                                  className="font-medium text-gray-700"
                                >
                                  {field.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </fieldset>
                      </Transition>
                      <Transition
                        show={selectedMode === modes[1]}
                        enter="transition-opacity duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <h5 className="text-sm font-medium mb-2 mt-6 leading-relaxed">
                          Select fields to display
                        </h5>
                        <fieldset className="space-y-5 grid grid-cols-2">
                          <legend className="sr-only">Fields</legend>
                          {selectedFieldsRecognition.map((field, index) => (
                            <div
                              className="relative flex items-start"
                              key={index}
                            >
                              <div className="flex items-center h-5">
                                <input
                                  id="username"
                                  name="username"
                                  checked={field.isChecked}
                                  onChange={() => {
                                    updateSelectedRecognition(index);
                                  }}
                                  type="checkbox"
                                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="sender"
                                  className="font-medium text-gray-700"
                                >
                                  {field.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </fieldset>
                      </Transition>
                      <div
                        className="inline-flex mt-8 cursor-pointer animate-enter items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={() => downloadData()}
                      >
                        <DocumentDownloadIcon
                          className="mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        <span className="-mx-0.5 mt-0.5">Download as CSV</span>
                      </div>
                    </div>
                  )}
                  {isLoading && (
                    <div className="mt-4 mb-4">
                      <LoadingIndicator />
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
