import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  CollectionIcon,
  CheckIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import { getTagCollections } from "../../state/stateStore";
import * as api from "../../services/api";

const CollectionModal = (props) => {
  const [collectionName, setCollectionName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  useEffect(() => {
    if (props.tagCollection) {
      setCollectionName(props.tagCollection.collection_name);
    } else {
      setCollectionName("");
    }
  }, [props.tagCollection]);

  const createCollection = async () => {
    setIsLoading(true);
    if (collectionName.length > 0) {
      let res;
      if (props.currentEnterprise && selectedWorkspaces.length > 0) {
        res = await api.createTagCollection(collectionName, selectedWorkspaces);
      } else {
        res = await api.createTagCollection(collectionName);
      }
      if (res === "OK") {
        props.closeModal();
        setCollectionName("");
        await getTagCollections();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const editCollection = async () => {
    setIsLoading(true);
    if (collectionName.length > 0) {
      let res = await api.editTagCollection(
        collectionName,
        props.tagCollection._id
      );
      if (res === "OK") {
        props.closeModal();
        setCollectionName("");
        await getTagCollections();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  let workspaces = null;
  if (props.currentEnterprise) {
    workspaces = props.currentEnterprise.workspaces;
  }

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <CollectionIcon
                    className="float-left h-4 w-4 mt-1 mr-2"
                    aria-hidden="true"
                  />{" "}
                  {props.tagCollection ? (
                    <div>
                      Edit collection - {props.tagCollection.collection_name}
                    </div>
                  ) : (
                    <div>Create a tag collection</div>
                  )}
                </Dialog.Title>
                <p className="text-sm mt-2 text-gray-600">
                  Tag collection is a collection which host multiple tags, you
                  can create or edit a tag collection here.
                </p>
                {props.currentEnterprise && (
                  <Listbox
                    value={selectedWorkspaces}
                    onChange={setSelectedWorkspaces}
                    multiple
                    className="mt-4"
                  >
                    <div className="relative mt-1">
                      <Listbox.Label className="block text-sm font-medium text-gray-700">
                        Workspaces
                      </Listbox.Label>
                      <Listbox.Button className="relative w-full cursor-pointer rounded-md bg-white border border-gray-300 py-4 mt-1 pl-3 pr-10 text-left sm:text-sm">
                        <span className="block truncate">
                          {" "}
                          {selectedWorkspaces
                            .map((workspace) => workspace.workspace_name)
                            .join(", ")}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-5/6 mr-10 cursor-pointer rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {workspaces.map((workspace, i) => (
                            <Listbox.Option
                              key={i}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-green-100 text-gree-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={workspace}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {workspace.workspace_name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                )}
                <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="tag_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Collection name
                    </label>
                    <div className="mt-1 flex w-full rounded-md shadow-sm">
                      <input
                        type="text"
                        name="collection_name"
                        id="collection_name"
                        required
                        placeholder="For example: Skills.."
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                        className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  {props.tagCollection && (
                    <button
                      type="button"
                      className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-amber-100 px-4 py-2 text-sm font-medium text-amber-900 hover:bg-amber-200 focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                      onClick={() => editCollection()}
                    >
                      Save collection
                    </button>
                  )}
                  {!props.tagCollection && (
                    <button
                      type="button"
                      className="focus:outline-none inline-flex float-right justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => createCollection()}
                      disabled={isLoading}
                    >
                      Create collection
                    </button>
                  )}
                  <button
                    type="button"
                    className="focus:outline-none inline-flex  float-right justify-center rounded-md border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-grey-900 hover:bg-grey-200 focus-visible:ring-2 focus-visible:ring-grey-500 focus-visible:ring-offset-2"
                    onClick={() => props.closeModal()}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CollectionModal;
