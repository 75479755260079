const MarketingKit = () => {
  return (
    <div className="mt-4">
      <p className="mt-1 text-sm text-gray-600">
        Let people know that you care about company culture, and
        sustainability.🌳 We created resources for you to use in your marketing,
        recruiting and website.
      </p>
      <a
        href="https://drive.google.com/drive/folders/1Yq5jCoYSb9KtvF5kjA-QxiD4JNrWISwP"
        target="_blank"
        rel="noreferrer"
        className="inline-flex mt-4 animate-enter items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-normal text-sm rounded-md text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Go to Marketing Kit
      </a>
    </div>
  );
};

export default MarketingKit;
