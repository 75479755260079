import { useSearchParams, useNavigate } from "react-router-dom";

const CheckoutComplete = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const success = searchParams.get("success");
  return (
    <div className="h-screen flex bg-coolBackground1">
      {success === "true" && (
        <div className="w-full max-w-2xl flex-col  animate-enter content-center m-auto bg-white justify-center rounded-md text-center shadow-sm py-10 px-16 xs:max-w-sm">
          <h1 className="text-2xl font-medium text-primaryGreen mt-4 text-center">
            Thank you for subscribing to Evergreen 🎉
          </h1>
          <p className="font-normal text-black mt-4 text-center">
            Now it is time to plant trees and save our planet. 🌳 And if you
            have any questions don’t hesitate to contact us - we're happy to
            help!
          </p>
          <img
            alt="Good job!"
            src={process.env.PUBLIC_URL + "/good-job.gif"}
            className="rounded-lg mx-auto mt-6"
          />
          <button
            type="button"
            onClick={() => navigate("/account")}
            className="inline-flex mt-8 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Back to account
          </button>
        </div>
      )}
      {success === "false" && (
        <div className="w-full max-w-2xl flex-col  animate-enter content-center m-auto bg-white justify-center rounded-md text-center shadow-sm py-10 px-16 xs:max-w-sm">
          <h1 className="text-2xl font-medium text-orange-500 mt-4 text-center">
            Whoops, seems you cancelled your checkout process 👀
          </h1>
          <p className="font-normal text-black mt-4 text-center">
            That's OK, you can try again from the account page and if you have
            any questions don’t hesitate to ask us - we're happy to help!
          </p>

          <button
            type="button"
            onClick={() => navigate("/account")}
            className="inline-flex mt-8 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primaryGreen hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Back to account
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckoutComplete;
