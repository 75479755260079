import { useTable, usePagination, useSortBy } from "react-table";
import React, { useState, useEffect, useCallback } from "react";
import { getReportingMemberData } from "../../state/stateStore";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  DocumentDownloadIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { getProfilePictureURI } from "../Helpers/helpers";
import DownloadCSVModal from "./DownloadCSVModal";
import DateRangeSelector from "../Common/DateRangeSelector";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = ({
  columns,
  data,
  pageCount: controlledPageCount,
  navigate,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ["user_id", "profile_pic"],
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData && fetchData(pageIndex, pageSize, sortBy);
  }, [pageIndex, pageSize, sortBy]);

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y table-auto divide-gray-300"
              >
                <thead className="bg-slate-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3.5 text-left text-sm font-medium text-slate-800"
                        >
                          <div className="group inline-flex">
                            {column.render("Header")}

                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="flex-none rounded text-gray-200 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const picture_uri = getProfilePictureURI(
                      row.values.profile_pic
                    );
                    const userId = row.values.user_id;
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                              key={i}
                            >
                              <div className="text-gray-700 flex items-center">
                                {cell.column.id === "user_name" ? (
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={picture_uri}
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                <span
                                  className={
                                    cell.column.id === "user_name"
                                      ? "ml-4 font-semibold cursor-pointer text-primaryGreen hover:undeline"
                                      : ""
                                  }
                                  onClick={() => {
                                    if (cell.column.id === "user_name") {
                                      navigate("/profile/" + userId);
                                    }
                                  }}
                                >
                                  {cell.render("Cell")}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between rounded-lg mt-4">
              <div className="ml-2 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </p>
                </div>
                <div className="ml-10 sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <select
                    className="relative py-2 pl-3 pr-10 text-left bg-white rounded-lg border-none shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 sm:text-xs"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize} per page
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                      className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReportingTable = (props) => {
  let navigate = useNavigate();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(props.currentTeam.create_date).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageIndexAndSize, setPageIndexAndSize] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: null,
  });

  const handleSearch = _.debounce(
    (search) => {
      setSearchTerm(search);
    },
    1000,
    {
      maxWait: 1000,
    }
  );

  const fetchData = useCallback((pageIndex, pageSize, sortBy) => {
    setPageIndexAndSize({ pageIndex, pageSize, sortBy });
    getMembersByDateRange(pageSize, pageSize * pageIndex, searchTerm, sortBy);
  });

  const getMembersByDateRange = async (limit, skip, search, sort) => {
    await getReportingMemberData(
      startDate,
      endDate,
      limit,
      skip,
      search,
      sort,
      props.isEnterprise,
      selectedWorkspace ? selectedWorkspace.workspace_id : null
    );
  };

  useEffect(() => {
    setSelectedWorkspace(
      props.selectedWorkspace ? props.selectedWorkspace : null
    );
    getMembersByDateRange(
      pageIndexAndSize.pageSize,
      pageIndexAndSize.pageSize * pageIndexAndSize.pageIndex,
      searchTerm,
      pageIndexAndSize.sortBy
    );
  }, [props.selectedWorkspace]);

  useEffect(() => {
    getMembersByDateRange(10, 0, searchTerm, pageIndexAndSize.sortBy);
  }, [searchTerm]);

  useEffect(() => {
    getMembersByDateRange(
      pageIndexAndSize.pageSize,
      pageIndexAndSize.pageSize * pageIndexAndSize.pageIndex,
      searchTerm,
      pageIndexAndSize.sortBy
    );
  }, [startDate]);

  useEffect(() => {
    getMembersByDateRange(
      pageIndexAndSize.pageSize,
      pageIndexAndSize.pageSize * pageIndexAndSize.pageIndex,
      searchTerm,
      pageIndexAndSize.sortBy
    );
  }, [endDate]);

  useEffect(() => {
    if (props.reportingData) {
      setPageCount(props.reportingData.paging.pages);
    }
  }, [props.reportingData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "user_id",
      },
      {
        Header: "Picture",
        accessor: "profile_pic",
      },
      {
        Header: "Name",
        accessor: "user_name",
      },
      {
        Header: "Recognitions given",
        accessor: "feedback_given",
      },
      {
        Header: "Recognitions received",
        accessor: "feedback_received",
      },
      {
        Header: "Seeds given",
        accessor: "seeds_given",
      },
      {
        Header: "Trees planted",
        accessor: "trees_planted",
      },
      {
        Header: "Carbon offset",
        accessor: "carbon_offset",
        Cell: (props) => {
          return <div>{props.value} kg</div>;
        },
      },
    ],
    []
  );

  const reportingData = props.reportingData;

  let tableData = [];
  //Map reporting data to CSV data and tableData
  reportingData &&
    reportingData.data &&
    reportingData.data.forEach((member) => {
      tableData.push({
        user_id: member.user_id,
        profile_pic: member.profile_pic,
        user_name: member.user_name,
        feedback_given: member.feedback_given,
        feedback_received: member.feedback_received,
        seeds_given: member.seeds_given,
        trees_planted: member.trees_planted,
        carbon_offset: member.trees_planted * 84,
      });
    });

  return (
    <section className="py-8">
      <DownloadCSVModal
        isOpen={isDownloadOpen}
        closeModal={() => setIsDownloadOpen(false)}
        isEnterprise={props.isEnterprise}
      />
      <div className="container mx-auto">
        <div className="pt-14 pb-12 sm:pb-16 bg-slate-50 shadow rounded-md p-10">
          <div>
            <h3 className="mb-8 sm:mb-0 text-xl font-heading text-slate-600 font-medium leading-8">
              People Analytics
            </h3>
            <p className="text-slate-500 mt-2 font-medium text-sm">
              <InformationCircleIcon
                className="h-4 w-4 float-left mr-1 mt-0.5"
                aria-hidden="true"
              />
              Click name to view more information
            </p>
            <div className="flex mb-4 -mx-4">
              <div className="px-4">
                <h5 className="text-sm font-medium mt-6 mb-2 text-slate-500 leading-relaxed">
                  Select date range
                </h5>
                <DateRangeSelector
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  currentTeam={
                    props.isEnterprise
                      ? props.currentEnterprise
                      : props.currentTeam
                  }
                />
              </div>
              <div className="px-4">
                <h5 className="text-sm font-medium sm:ml-4 mt-6 mb-2 text-slate-500 leading-relaxed">
                  Search
                </h5>
                <input
                  id="search"
                  type="text"
                  placeholder="Search by name.."
                  className="w-full text-base md:text-sm sm:ml-4 bg-white border-none rounded-md shadow form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-green-500"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div className="px-4">
                <button
                  className="mb-2 inline-flex mt-14 mr-2 float-right items-center px-3 py-2 text-xs hover:bg-green-100 rounded-lg leading-4 font-medium text-primaryGreen"
                  onClick={() => {
                    setIsDownloadOpen(true);
                  }}
                >
                  <DocumentDownloadIcon
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  <span className="-mx-0.5 mt-0.5">Download as CSV</span>
                </button>
              </div>
            </div>
            <Table
              columns={columns}
              data={tableData}
              navigate={navigate}
              fetchData={fetchData}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportingTable;
