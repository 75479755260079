import { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import moment from "moment";
import { format } from "date-fns";
import axios from "axios";
import { getProfilePictureURI } from "../Helpers/helpers";
import StatsChart from "./StatsChart";
import ManageTeamModal from "./ManageTeamModal";
import ViewUsersTable from "./ViewUsersTable";
import DatePicker from "react-datepicker";

const TeamPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [trialStartDate, setTrialStartDate] = useState(null);
  const [confrimExtendTrial, setConfirmExtendTrial] = useState(false);
  const [team, setTeam] = useState(null);
  const params = useParams();
  const teamId = params.teamId;

  const getTeamData = async (teamId) => {
    await axios
      .post("/admin/data/team", { teamId })
      .then((res) => {
        setTeam(res.data.team);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  const changeSubscriptionStatus = async (teamId, subscriptionStatus) => {
    await axios
      .post("/admin/change-subscription", {
        team_id: teamId,
        status: subscriptionStatus,
      })
      .then((res) => {
        getTeamData(teamId);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  const extendTrial = async (teamId, trialStartDate) => {
    await axios
      .post("/admin/extend-trial", {
        team_id: teamId,
        start_date: trialStartDate,
      })
      .then((res) => {
        getTeamData(teamId);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  const endTrial = async (teamId) => {
    await axios
      .post("/admin/end-trial", {
        team_id: teamId,
      })
      .then((res) => {
        getTeamData(teamId);
      })
      .catch((error, res) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getTeamData(teamId);
    setIsLoading(false);
  }, [teamId]);

  useEffect(() => {
    if (team) {
      setTrialStartDate(team.trial.start_date);
    }
  }, [teamId]);

  if (!isLoading && team) {
    return (
      <>
        <ManageTeamModal
          team={team}
          isOpen={manageModalOpen}
          closeModal={() => setManageModalOpen(false)}
        />
        <div className="py-2 animate-fade">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex items-center space-x-5 mt-8 py-4">
              <div>
                <div className="flex items-center">
                  <img
                    src={getProfilePictureURI(team.workspace_pic)}
                    className="h-10 w-10 rounded-md"
                  />
                  <h1 className="text-3xl font-bold ml-3 text-gray-900">
                    {team.team_domain}
                    <button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="ml-4 inline-flex mt-1 items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium text-sm rounded-md text-slate-700 bg-slate-100 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                    >
                      <ChevronLeftIcon
                        className="float-left h-4 w-4 mr-1 -ml-1"
                        aria-hidden="true"
                      />
                      Back
                    </button>
                  </h1>
                </div>
                <p className="text-slate-600 mt-4">
                  Created {moment(team.create_date).format("DD.MM.YYYY")}
                </p>
              </div>
            </div>
            <section className="py-6">
              <div className="flex flex-wrap -mx-2">
                <div className="w-full lg:w-1/4 px-2 mb-4 lg:mb-0">
                  <div className="h-full px-8 py-6 bg-slate-50 shadow rounded-md">
                    <div className="flex items-center mb-4">
                      <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                        🎉 Recognition given
                      </h3>
                    </div>
                    <div className="flex-col items-center mb-5">
                      <div className="flex items-center text-4xl py-2 font-heading font-medium">
                        {team.monthly_feedback_given}
                        <span className="text-lg ml-3 text-gray-600 font-heading font-medium">
                          Month
                        </span>
                      </div>
                      <div className="flex items-center text-4xl font-heading font-medium">
                        {team.total_feedback_given}
                        <span className="text-lg ml-3 text-gray-600 font-heading font-medium">
                          Total
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="flex items-center mt-6 mb-4">
                      <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                        🌳 Trees planted
                      </h3>
                    </div>
                    <div className="flex-col items-center mb-5">
                      <div className="flex items-center text-4xl py-2 font-heading font-medium">
                        {team.monthly_trees_planted}
                        <span className="text-lg ml-3 text-gray-600 font-heading font-medium">
                          Month
                        </span>
                      </div>
                      <div className="flex items-center text-4xl font-heading font-medium">
                        {team.total_seeds_planted}
                        <span className="text-lg ml-3 text-gray-600 font-heading font-medium">
                          Total
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    manageModalOpen
                      ? "transform scale-110 -rotate-12 w-full lg:w-1/4 px-2 mb-4 lg:mb-0 transition-all"
                      : "w-full lg:w-1/4 px-2 mb-4 lg:mb-0 transition-all duration-300"
                  }
                >
                  <div className="h-full px-8 py-6 bg-slate-50 shadow rounded-md">
                    <div className="flex items-center mb-4">
                      <h3 className="mr-auto text-sm text-darkBlueGray-300 font-heading font-medium">
                        👨‍💻 Team admins
                        <span
                          className="cursor-pointer ml-4 text-xs rounded-md px-2 py-1 bg-gray-200 text-gray-800"
                          onClick={() => setManageModalOpen(true)}
                        >
                          Manage
                        </span>
                      </h3>
                    </div>
                    <div className="flex items-center">
                      <span className="inline-block mr-2 text-md font-normal">
                        {team.admin_users.map((admin) => {
                          return (
                            <div className="flex items-center w-full">
                              <a
                                href={"mailto:" + admin.email}
                                className="block my-2 w-full"
                              >
                                <div className="flex w-full items-center">
                                  <img
                                    src={getProfilePictureURI(
                                      admin.profile_pic32
                                    )}
                                    className="h-6 w-6 rounded-full"
                                  />
                                  <span className="ml-2 text-sm font-medium">
                                    {admin.user_name}
                                  </span>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/4 px-2 mb-4 lg:mb-0">
                  <div className="h-full px-8 py-6 bg-slate-50 shadow rounded-md">
                    <div className="flex items-center mb-4">
                      <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                        🚨 Status
                      </h3>
                    </div>
                    <div className="flex items-center">
                      <ul role="list" className="divide-y divide-gray-200">
                        <li className="py-3 flex items-center">
                          {team.team_feedback_channel_set ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              Recognition channel set
                            </p>
                          </div>
                        </li>
                        <li className="py-3 flex items-center">
                          {team.impact_dash_enabled ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              Impact dashboard
                            </p>
                          </div>
                        </li>
                        <li className="py-3 flex items-center">
                          {team.recap_boolean ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              Recap enabled
                            </p>
                          </div>
                        </li>
                        <li className="py-3 flex items-center">
                          {team.team_values.length > 0 ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              Values in use
                            </p>
                          </div>
                        </li>
                        <li className="py-3 flex items-center">
                          {team.extra_seeds > 0 ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              {team.extra_seeds > 0 ? (
                                <>
                                  Extra seeds amount: {team.extra_seeds} seeds
                                </>
                              ) : (
                                <>No extra seeds</>
                              )}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/4 px-2 mb-4 lg:mb-0">
                  <div className="h-full px-8 py-6 bg-slate-50 shadow rounded-md">
                    <div className="flex items-center mb-4">
                      <h3 className="mr-auto pr-1 text-sm text-darkBlueGray-300 font-heading font-medium">
                        💵 Subscription
                      </h3>
                    </div>
                    <div className="flex items-center">
                      <ul role="list" className="divide-y divide-gray-200">
                        <li className="py-3 flex items-center">
                          {team.subscription.is_active ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              Subscribed
                            </p>
                            <div className="text-xs">
                              <p className="text-gray-500">
                                {moment(team.subscription.start_date).format(
                                  "DD.MM.YYYY"
                                )}{" "}
                                -{" "}
                                {team.subscription.end_date ? (
                                  moment(team.subscription.end_date).format(
                                    "DD.MM.YYYY"
                                  )
                                ) : (
                                  <span>Ongoing</span>
                                )}
                              </p>
                              {team.subscription.is_active ? (
                                <span
                                  className="text-red-600 cursor-pointer"
                                  onClick={() => {
                                    changeSubscriptionStatus(
                                      teamId,
                                      "UNSUBSCRIBE"
                                    );
                                  }}
                                >
                                  Deactivate
                                </span>
                              ) : (
                                <span
                                  className="text-green-600 cursor-pointer"
                                  onClick={() => {
                                    changeSubscriptionStatus(
                                      teamId,
                                      "SUBSCRIBE"
                                    );
                                  }}
                                >
                                  Activate
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 flex items-center">
                          {team.trial.is_active ? (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </div>
                          ) : (
                            <div className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-red-100 text-red-800">
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </div>
                          )}
                          <div className="ml-4">
                            <p className="text-sm font-normal text-gray-900">
                              {team.trial.is_active
                                ? "Trial ongoing"
                                : "Trial ended"}
                            </p>
                            <div className="text-xs">
                              <p className="text-gray-500">
                                {moment(team.trial.start_date).format(
                                  "DD.MM.YYYY"
                                )}{" "}
                                -{" "}
                                {team.trial.end_date ? (
                                  moment(team.trial.end_date).format(
                                    "DD.MM.YYYY"
                                  )
                                ) : (
                                  <span>Ongoing</span>
                                )}
                              </p>
                              <Popover className="relative">
                                {({ close }) => (
                                  <>
                                    <Popover.Button className="text-green-600 cursor-pointer">
                                      Extend trial
                                    </Popover.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-200"
                                      enterFrom="opacity-0 translate-y-1"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-150"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-1"
                                    >
                                      <Popover.Panel className="absolute z-20 mt-3 -mx-12 max-w-md transform px-4 sm:px-0 lg:max-w-md">
                                        <div className="overflow-hidden rounded-md shadow-md ring-1 ring-black ring-opacity-5 cursor-pointer">
                                          <div className="bg-slate-50 px-6 py-5">
                                            <h5 className="text-md font-normal mb-4 leading-relaxed">
                                              Select new trial start date
                                            </h5>
                                            {!confrimExtendTrial && (
                                              <DatePicker
                                                selected={trialStartDate}
                                                className="cursor-pointer"
                                                onChange={(date) => {
                                                  setConfirmExtendTrial(true);
                                                  setTrialStartDate(date);
                                                }}
                                                dateFormat="dd.MM.yyyy"
                                                renderCustomHeader={({
                                                  date,
                                                  decreaseMonth,
                                                  increaseMonth,
                                                  prevMonthButtonDisabled,
                                                  nextMonthButtonDisabled,
                                                }) => (
                                                  <div className="flex items-center justify-between px-2 py-2">
                                                    <span className="text-lg text-gray-700">
                                                      {format(
                                                        date,
                                                        "MMMM yyyy"
                                                      )}
                                                    </span>

                                                    <div className="space-x-2">
                                                      <button
                                                        onClick={decreaseMonth}
                                                        disabled={
                                                          prevMonthButtonDisabled
                                                        }
                                                        type="button"
                                                        className={`
                            ${
                              prevMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                                      >
                                                        <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                                      </button>

                                                      <button
                                                        onClick={increaseMonth}
                                                        disabled={
                                                          nextMonthButtonDisabled
                                                        }
                                                        type="button"
                                                        className={`
                            ${
                              nextMonthButtonDisabled &&
                              "cursor-not-allowed opacity-50"
                            }
                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-green-500
                        `}
                                                      >
                                                        <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            )}
                                            {confrimExtendTrial && (
                                              <div className="flex-col items-center justify-between">
                                                Are you sure you want to set the
                                                new trial start date to{" "}
                                                {moment(trialStartDate).format(
                                                  "DD.MM.YYYY"
                                                )}
                                                ?
                                                <div className="space-x-2 mt-2">
                                                  <button
                                                    onClick={() => {
                                                      extendTrial(
                                                        teamId,
                                                        trialStartDate
                                                      );
                                                      setConfirmExtendTrial(
                                                        false
                                                      );
                                                      close();
                                                    }}
                                                    className="bg-primaryGreen text-white px-2 py-1 rounded"
                                                  >
                                                    Yes
                                                  </button>
                                                  <button
                                                    className="bg-gray-200 px-2 py-1 rounded"
                                                    onClick={() => {
                                                      setTrialStartDate(null);
                                                      setConfirmExtendTrial(
                                                        false
                                                      );
                                                      close();
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Popover>
                              {team.trial.is_active && (
                                <span
                                  className="text-red-600 cursor-pointer"
                                  onClick={() => {
                                    endTrial(teamId);
                                  }}
                                >
                                  End trial
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <StatsChart monthlyData={team.monthly_data_collection} />
            <ViewUsersTable teamId={teamId} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <svg
        role="status"
        className="w-10 h-10 mt-12 text-gray-200 animate-spin mx-auto fill-primaryGreen"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    );
  }
};

export default TeamPage;
