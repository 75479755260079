import moment from "moment";
import React, { useEffect, useState, Fragment } from "react";
import { ChartBarIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Transition, Listbox } from "@headlessui/react";
import {
  state,
  useSnapshot,
  getDataReporting,
  getDataByMonth,
} from "../../state/stateStore";

// Components
import ReportingTable from "./ReportingTable";
import DataChart from "./DataChart";
import StatsBoxes from "./StatsBoxes";
import { LoadingIndicator } from "../Helpers/helpers";

const Reporting = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const snap = useSnapshot(state);
  const currentUser = snap.currentUser;
  const currentTeam = snap.currentTeam;
  const currentEnterprise = snap.currentEnterprise;
  const reportingData30d = snap.reportingData30d;
  const reportingData60d = snap.reportingData60d;
  const reportingDataByMonth = snap.reportingDataMonthly;
  const reportingMemberData = snap.reportingMemberData;

  const getMembersBy30d = async (isEnterprise, workspaceId) => {
    setIsLoading(true);
    await getDataReporting(
      moment().subtract(30, "days").toDate(),
      moment().toDate(),
      "30d",
      isEnterprise,
      workspaceId
    );
    setIsLoading(false);
  };

  const getMembersBy60d = async (isEnterprise, workspaceId) => {
    setIsLoading(true);
    await getDataReporting(
      moment().subtract(60, "days").toDate(),
      moment().subtract(30, "days").toDate(),
      "60d",
      isEnterprise,
      workspaceId
    );
    setIsLoading(false);
  };

  useEffect(() => {
    // If there is a workspace in enterprise selected, get the data for the workspace
    if (selectedWorkspace) {
      if (selectedWorkspace.whole_enterprise) {
        // Get whole enterprise data
        getMembersBy30d(true, null);
        getMembersBy60d(true, null);
        getDataByMonth(moment().month() + 1, moment().year(), true, null);
      } else {
        // Get data for workspace
        getMembersBy30d(true, selectedWorkspace.workspace_id);
        getMembersBy60d(true, selectedWorkspace.workspace_id);
        getDataByMonth(
          moment().month() + 1,
          moment().year(),
          true,
          selectedWorkspace.workspace_id
        );
      }
    } else if (currentEnterprise) {
      getMembersBy30d(true, null);
      getMembersBy60d(true, null);
      getDataByMonth(moment().month() + 1, moment().year(), true, null);
    }
    // Else normal team data, not enterprise
    else {
      getMembersBy30d();
      getMembersBy60d();
      getDataByMonth(moment().month() + 1, moment().year(), false);
    }
  }, [selectedWorkspace]);

  if (
    currentUser &&
    currentTeam &&
    reportingData30d &&
    reportingData60d &&
    reportingDataByMonth
  ) {
    let createDate;
    if (currentEnterprise) {
      createDate = currentEnterprise.create_date;
    } else {
      createDate = currentTeam.create_date;
    }
    return (
      <>
        <section className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="container mx-auto my-8">
              <div className="flex flex-col">
                <div className="flex">
                  <h2 className="text-2xl font-semibold text-black leading-relaxed float-left">
                    <ChartBarIcon
                      className="float-left h-8 w-8 mt-0.5 mr-2"
                      aria-hidden="true"
                    />{" "}
                    Reporting
                  </h2>
                  {currentEnterprise && (
                    <Listbox
                      value={selectedWorkspace}
                      onChange={setSelectedWorkspace}
                      className="ml-6"
                    >
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-56 cursor-pointer rounded-md bg-white border border-gray-300 py-2 mt-1 pl-3 pr-10 text-left sm:text-sm">
                          <span className="block truncate">
                            {" "}
                            {selectedWorkspace
                              ? selectedWorkspace.workspace_name
                              : "Select workspace"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="z-50 absolute mt-1 w-56 mr-10 cursor-pointer rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <Listbox.Option
                              key={1}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-green-100 text-gree-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={{
                                id: 1,
                                workspace_name:
                                  currentEnterprise.enterprise_name,
                                workspace_id: null,
                                whole_enterprise: true,
                              }}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {currentEnterprise.enterprise_name} (all
                                    workspaces)
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>

                            {currentEnterprise.workspaces.map(
                              (workspace, i) => (
                                <Listbox.Option
                                  key={workspace.workspace_id}
                                  className={({ active }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-green-100 text-gree-900"
                                        : "text-gray-900"
                                    }`
                                  }
                                  value={{
                                    id: workspace._id,
                                    workspace_name: workspace.workspace_name,
                                    workspace_id: workspace.workspace_id,
                                    whole_enterprise: false,
                                  }}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {workspace.workspace_name}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              )
                            )}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  )}
                  {isLoading && <LoadingIndicator />}
                </div>
                <p className="text-slate-600 mt-2">
                  Your organization's Evergreen usage data
                </p>
              </div>
              <div className="animate-enter transition-all duration-300 ease-in-out">
                <StatsBoxes
                  reportingData30d={reportingData30d}
                  reportingData60d={reportingData60d}
                />
                <DataChart
                  monthlyData={reportingDataByMonth}
                  creationDate={createDate}
                  setIsLoading={setIsLoading}
                  selectedWorkspace={
                    selectedWorkspace ? selectedWorkspace : null
                  }
                  isEnterprise={currentEnterprise ? true : false}
                />
                {currentUser.admin && (
                  <ReportingTable
                    reportingData={reportingMemberData}
                    selectedWorkspace={
                      selectedWorkspace ? selectedWorkspace : null
                    }
                    isEnterprise={currentEnterprise ? true : false}
                    currentTeam={currentTeam}
                    currentEnterprise={currentEnterprise}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <svg
        role="status"
        className="w-10 h-10 mt-24 text-gray-200 animate-spin mx-auto dark:text-gray-600 fill-primaryGreen"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    );
  }
};

export default Reporting;
